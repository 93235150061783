import { useRef, useEffect, useState, RefObject } from 'react';

type InScreenProps = {
  ref: RefObject<Element | HTMLInputElement>;
  threshold?: number;
  rootMargin?: string;
};

type ReturnType = {
  hasIntersected: boolean;
};

export function useInScreen({
  ref,
  threshold = 0.0,
  rootMargin = '0px'
}: InScreenProps): ReturnType {
  const observer = useRef<IntersectionObserver | null>(null);
  const [hasIntersected, setHasIntersected] = useState(false);
  const onIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setHasIntersected(true);
      }
    });
  };

  useEffect(() => {
    if ('IntersectionObserver' in window) {
      observer.current = new IntersectionObserver(onIntersection, {
        root: null, // viewport
        rootMargin,
        threshold
      });
      if (ref.current) {
        observer.current.observe(ref.current);
      } else {
        setHasIntersected(true);
      }
    }

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [ref]);

  return {
    hasIntersected
  };
}
