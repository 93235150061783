import { initiateGam } from '@client/adManager/initiateGam';
import { AdnAdVendor } from '@client/adnuntius/AdnAdVendor';
import { initiateAdn } from '@client/adnuntius/initiateAdn';
import { initiate as initiateAdnamiOverlapScrollEvent } from '@client/core//utils/dom/handleAdnamiOverlap';
import { setupEvents } from '@client/core//utils/events';
import { setupOverrideHelper } from '@client/core//utils/overrideHelper';
import { defineAdvtComponent } from '@client/core/components/webComponents';
import { advtPerformance } from '@schibsted-nmp/advertising-events';
import {
  AdServer,
  InitialState,
  debugLog,
  hasQueryParam
} from '@schibsted-nmp/advertising-shared';
import { GamAdVendor } from '@client/adManager/GamAdVendor';

import { setPageOptsInXandr } from './xandr/setPageOpts';

/**
 * Initiates ad rendering, setup page options and handles each placement.
 * @param initialState The initial state for setting up ads.
 */
export async function initiateAdvt(initialState: InitialState): Promise<void> {
  advtPerformance.markXandrEvents(`initiateAdvt`);
  advtPerformance.markXandrEvents(
    `Expect placements: ${initialState?.config?.placements
      ?.map((p) => p.placementId)
      .join(', ')}`
  );

  debugLog('Inside initiateAdvt()');

  const { config, env } = initialState;

  // set meta tag to header
  const meta = document.createElement('meta');
  meta.httpEquiv = 'delegate-ch';
  meta.content =
    'sec-ch-ua-model https://ib.adnxs.com; sec-ch-ua-platform-version https://ib.adnxs.com';
  document.head.appendChild(meta);

  if (env === 'local' || hasQueryParam('advt_console')) {
    debugLog('Rendering dev panel');
    const { renderDevPanel } = await import('./local');
    renderDevPanel();
  }

  if (config.adServer.xandr) {
    await setPageOptsInXandr(config);
  }

  setupEvents({
    config,
    pageType: config.pageType
  });

  if (config?.adServer.xandr) {
    setupOverrideHelper(
      config.placements.filter((p) => p.adServer.type === AdServer.Xandr)
    );
  }

  if (config.deviceType === 'desktop') {
    initiateAdnamiOverlapScrollEvent();
  }

  defineAdvtComponent();

  if (config?.adServer.gam) {
    initiateGam(config.adServer.gam);
    GamAdVendor.setupBatchRequesting();
  }

  if (config?.adServer.adn) {
    initiateAdn(config.adServer.adn);
    AdnAdVendor.setupBatchRequesting();
  }
}
