import {
  AdConfig,
  InitialState,
  Keywords,
  PerformanceEntry
} from '@schibsted-nmp/advertising-shared';

export const setInitialState = (payload: InitialState) => ({
  type: 'SET_INITIAL_STATE',
  payload
});

export const setSearchParams = (payload: {
  current?: Record<string, any>;
  new?: Record<string, any>;
}) => ({
  type: 'SET_SEARCH_PARAMS',
  payload
});

export const setConfig = (payload: AdConfig) => ({
  type: 'SET_CONFIG',
  payload
});

export const setPpid = (payload: {
  isLoggedIn: boolean;
  ppid1: string;
  ppid2: string;
  externalId: string;
}) => ({
  type: 'SET_PPID',
  payload
});

export const addPageOpts = (payload: Record<string, any>) => ({
  type: 'SET_PAGE_OPTS',
  payload
});

export const updateKeywords = (payload: Keywords) => ({
  type: 'UPDATE_KEYWORDS',
  payload
});

export const setCycle = (payload: number) => ({
  type: 'SET_CYCLE',
  payload
});

export const addPerformanceEntry = (payload: PerformanceEntry) => ({
  type: 'ADD_PERFORMANCE_ENTRY',
  payload
});
