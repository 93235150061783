import React from 'react';

import { createRoot } from 'react-dom/client';

import DevPanel from './DevPanel';

export function renderDevPanel() {
  const divRoot = document.createElement('div');
  divRoot.id = 'advt-dev-panel-root';
  document.body.appendChild(divRoot);
  const root = createRoot(divRoot);
  root.render(<DevPanel />);
}
