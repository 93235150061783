import { css } from 'lit';
import classNames from 'classnames';

import {
  AdStatus,
  AdType,
  UNLEASH_FEATURE_NAME
} from '@schibsted-nmp/advertising-shared';

import { isFeatureEnabled } from '../../state/reducer';

export function getClassNameByStatus(status: AdStatus) {
  const invisibleInsteadOfHidden = isFeatureEnabled(
    UNLEASH_FEATURE_NAME.enableGamForecasting
  );
  return classNames({
    loaded: status === 'available' || status === 'loaded',
    hidden: status === 'failed' && !invisibleInsteadOfHidden,
    'hidden-for-forecasting': status === 'failed' && invisibleInsteadOfHidden
  });
}
export function getClassNameByType(type: AdType) {
  return classNames({
    'native-ad': type === 'native',
    'video-ad': type === 'video',
    'banner-ad': type === 'banner'
  });
}

export default css`
  :host(.advt-x) {
    display: block;
    position: relative;
    z-index: 2;
  }

  :host(.advt-g) {
    margin: 0 auto;
    overflow: hidden;
    grid-column: 1 / -1;
    width: 100%;
    min-height: 10px;
  }

  :host(.banner-ad[placementid*='advt_recirc_middle_2']),
  :host(.banner-ad[placementid*='board_']),
  :host(.banner-ad[placementid*='advt_middle']) {
    margin: 0 auto;
    overflow: hidden;
    grid-column: 1 / -1;
  }

  :host([placementid='advt_wallpaper']) {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50vw);
    z-index: -1;
    width: 100vw;
    overflow: hidden;
  }

  :host([placementid='advt_top_2']) {
    width: 100%;
    max-width: 100%;
    min-height: 50px;
    margin: 12px 0;
    overflow: hidden;
  }

  /* Handle 5 rows banner ad in recirc placement 1 */
  :host(.banner-ad[placementId='advt_recirc_middle_1']) {
    grid-column: 1 / 3;
  }

  :host([placementid='advt_bottom_1']) {
    overflow: visible;
    margin: 20px 0;
  }

  :host([placementid='advt_bottom_1']) #advt_bottom_1 {
    margin-left: auto;
  }

  :host([placementid*='advt_recirc_middle_']) #advt_bottom_1 {
    width: 100%;
  }

  :host(.video-ad[placementid*='advt_recirc_middle_']) {
    margin: 0 auto;
    grid-column: 1 / -1;
  }

  :host(.video-ad[placementid*='board_']) iframe {
    margin-left: auto;
    margin-right: auto;
  }

  :host(.native-ad[placementid*='board_']),
  :host(.native-ad[placementid*='middle_']) {
    grid-column: unset;
    margin: unset;
    overflow: visible;
  }

  @media (max-width: 989px) {
    :host(.banner-ad[placementid*='advt_recirc_middle_1']) {
      margin: 0 auto;
      overflow: hidden;
      grid-column: 1 / -1;
    }
  }

  :host(.loaded) {
    display: block;
  }

  :host(.hidden) {
    display: none;
  }

  :host(.hidden-for-forecasting[placementid*='middle_']) {
    grid-column: 1 / -1;
    visibility: hidden;
  }

  :host(.banner-ad[placementid*='adn_wallpaper_1']) {
    max-width: 100vw;
    overflow: hidden;
  }

  :host([placementid*='forecasting']) > * > * {
    opacity: 0;
    width: 1px;
    height: 1px;
    position: absolute;
  }

  /* Don't overflow for mobile devices on middle placements, it breaks the UI */
  @media (max-width: 767px) {
    :host(.banner-ad[placementid*='bottom_1']),
    :host(.banner-ad[placementid*='board_']),
    :host(.banner-ad[placementid*='middle_']),
    :host(.banner-ad[placementid*='advt_top_2']) {
      overflow: hidden;
      max-width: 100%;
    }
    :host(.loaded) {
      overflow: hidden;
    }
  }
`;
