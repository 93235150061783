"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UNLEASH_FEATURE_ARRAY = exports.UNLEASH_FEATURE_NAME = exports.getUnleashFeatureWithPodletPrefix = void 0;
// Add your new Unleash Keys here without the 'advertising-podlet.' prefix
var UNLEASH_FEATURE_NAMES = [
    'killswitch',
    'mockApi',
    'iframeHeightOnBanners',
    'xandrFallbackVersion',
    'forceUnleashTestMemberId',
    'disableRelevantDigital',
    'enableAdditionalTestKeyValuePageOpts',
    'enableSafeFrame',
    'disableAfsPlacements',
    'disableNoMediationBidHandler',
    'enableTop1TestPlacement',
    'enableRelevantRight1DynamicSizes',
    'disableXandrLoadTagsDebounce',
    'enableGam',
    'enableGamTestCampaign',
    'enableAdnuntius',
    'enableGamForecasting',
    'disableLegacyAdvertisingConfigApi',
    'disableAdvertisingConfigApi',
    'enableContentfulFetcher'
];
function getUnleashFeatureWithPodletPrefix(nameId) {
    return "advertising-podlet.".concat(nameId);
}
exports.getUnleashFeatureWithPodletPrefix = getUnleashFeatureWithPodletPrefix;
exports.UNLEASH_FEATURE_NAME = UNLEASH_FEATURE_NAMES.reduce(function (acc, key) {
    // @ts-ignore - TS doesn't like the dynamic key
    acc[key] = key;
    return acc;
}, {});
exports.UNLEASH_FEATURE_ARRAY = Object.values(exports.UNLEASH_FEATURE_NAME);
