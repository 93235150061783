import { useEffect, useState } from 'react';

import {
  AdObj,
  PlacementId,
  debugLog,
  UNLEASH_FEATURE_NAME,
  AdStatus,
  isTakeover
} from '@schibsted-nmp/advertising-shared';
import { getPageOptsWithConsentKeyword } from '@client/xandr/externalId';
import { advtPerformance } from '@schibsted-nmp/advertising-events';
import { injectTakeoverTemplate } from '@client/xandr/components/takeover/util';
import { getTagConfig } from '@client/xandr/tag';
import {
  getAdConfig,
  getState,
  isFeatureEnabled
} from '@client/core/state/reducer';
import { checkForNoBidCreatives } from '@client/xandr/mediationNoBidHandler';
import {
  defineTag,
  getDebouncedLoadTagsFunction,
  loadAstTags,
  onAdAvailable,
  onAdBadRequest,
  onAdLoaded,
  onAdLoadedMediated,
  onAdNoBid,
  onAdNoBidMediated,
  onAdRequested,
  setPageOpts,
  XandrLifecycleEvents
} from '@client/xandr/xandr';

import { getConsentOrSubscribe } from '../utils/consent';

export type SetupAdProps = {
  placementId: PlacementId; // The actual placementId xandr uses
  targetId: string; // Used for cycling and actual targeting
  containerId: string; // The container the ad is loaded into from renderAd()
};

const INITIAL_STATUS = 'requested' as AdStatus;
export function useSetupAd({ placementId, targetId }: SetupAdProps) {
  const [status, setStatus] = useState<AdStatus>(INITIAL_STATUS);
  const [adObj, setAdObj] = useState<AdObj>();

  const tagConfig = getTagConfig(placementId as PlacementId, targetId);

  useEffect(() => {
    onAdRequested(targetId, () => {
      debugTools(placementId, `Requested ${targetId}`, 'Requested');
    });

    onAdAvailable(targetId, (_adObj: AdObj) => {
      if (checkForNoBidCreatives(placementId, _adObj.creativeId)) {
        debugTools(
          placementId,
          `noMediationBid for ${targetId} with Creative ID: ${_adObj.creativeId}`,
          'No bid'
        );
        setStatus('failed');
        return;
      }

      if (isTakeover(_adObj, getAdConfig().deviceType)) {
        injectTakeoverTemplate(window.apntag, placementId);
      }
      debugTools(
        placementId,
        `adAvailable: Creative ID for ${targetId}: ${_adObj.creativeId}`,
        'Available',
        _adObj
      );
      debugLog('adAvailable:', { adObj: _adObj });
      setStatus('available');
      setAdObj(_adObj);
    });

    onAdLoaded(targetId, (_adObj: AdObj) => {
      debugTools(
        placementId,
        `adLoaded: Creative ID for ${targetId}: ${_adObj.creativeId}`,
        'Loaded'
      );
      setStatus('loaded');
      setAdObj(_adObj);
    });

    onAdLoadedMediated(targetId, (_adObj: AdObj) => {
      debugTools(
        placementId,
        `adLoadedMediated: Creative ID for ${targetId}: ${_adObj.creativeId}`,
        'Loaded'
      );
    });

    onAdBadRequest(targetId, () => {
      debugTools(placementId, `adBadRequest for ${targetId}`, 'Bad request');
      setStatus('failed');
    });
    onAdNoBid(targetId, () => {
      debugTools(placementId, `adNoBid for ${targetId}`, 'No bid');
      setStatus('failed');
    });
    onAdNoBidMediated(targetId, () => {
      debugTools(placementId, `adNoBidMediated for ${targetId}`, 'No bid');
      setStatus('failed');
    });

    getConsentOrSubscribe((permissionValue) => {
      debugLog('getConsentOrSubscribe ', { permissionValue });
      const { config } = getState();
      if (config?.adServer.xandr?.pageOpts) {
        const pageOpts = getPageOptsWithConsentKeyword(
          config.adServer.xandr.pageOpts,
          permissionValue
        );
        setPageOpts(pageOpts, () => debugLog('pageOpts set', pageOpts));
      }
      if (tagConfig) {
        defineTag(tagConfig);
        if (
          isFeatureEnabled(UNLEASH_FEATURE_NAME.disableXandrLoadTagsDebounce)
        ) {
          loadAstTags();
        } else {
          getDebouncedLoadTagsFunction()();
        }
      }
    });

    const cleanupEvents = () => {
      if (!window.apntag?.anq) return;

      [
        XandrLifecycleEvents.AD_REQUESTED,
        XandrLifecycleEvents.AD_AVAILABLE,
        XandrLifecycleEvents.AD_LOADED,
        XandrLifecycleEvents.AD_NO_BID,
        XandrLifecycleEvents.AD_BAD_REQUEST
      ].forEach((eventType) => {
        window.apntag.anq.push(() =>
          window.apntag?.offEvent(eventType, targetId)
        );
      });
    };

    return () => {
      setAdObj(undefined);
      cleanupEvents();
    };
  }, [targetId]);

  return { status, adObj };
}

/**
 * ===========================================================================
 * The following functions are purely used for debugging and logging purposes.
 */

function setLocalEntry(
  placementId: PlacementId,
  localEntryText: string,
  adObj?: AdObj
) {
  if (getState().initialState?.env === 'local') {
    const localEntry = document.getElementById(`${placementId}-local-entry`);
    if (localEntry) {
      const sizes =
        adObj?.source === 'rtb' ? `${adObj.width}x${adObj.height}` : '';
      localEntry.innerHTML += `<p class='m-0 text-12'> - ${localEntryText} ${
        adObj ? `(${adObj.adType}, ${sizes})` : ''
      }</p>`;
    }
  }
}

function debugTools(
  placementId: PlacementId,
  logEntry: string,
  localEntry: string,
  adObj?: AdObj
) {
  debugLog(logEntry);
  advtPerformance.markXandrEvents(logEntry);
  setLocalEntry(placementId, localEntry, adObj);
}
