import {
  PageOpts,
  PPIDUserObject,
  Brand,
  PermissionValue,
  PersistedPPID
} from '@schibsted-nmp/advertising-shared';

import { getPPID } from './ppid';

export function getBrandCode(brand?: Brand) {
  return brand === 'finn'
    ? 'NO'
    : brand === 'tori'
      ? 'FI'
      : brand === 'blocket'
        ? 'SE'
        : brand === 'dba'
          ? 'DK'
          : 'NO';
}
function getExtIdsWithBrand(brand: Brand, ppid1?: string, ppid2?: string) {
  const extIds: PPIDUserObject[] = [];
  if (ppid1) {
    extIds.push({
      source: `SCH${getBrandCode(brand)}-UserHash`,
      id: ppid1
    } as PPIDUserObject);
  }
  if (ppid2) {
    extIds.push({
      source: `SCH${getBrandCode(brand)}-EnvHash`,
      id: ppid2
    } as PPIDUserObject);
  }
  return extIds;
}
/**
 * Set pageOpts:
 * PPID in pageOpts.user.userIds and pageOpts.eids (because doc says to set it on userIds, but it only works if you set it
 * directly on eids.
 *
 * Old solution which we need to keep:
 * pageOpts.user.externalUid: adId (which is basically the same as SCHNO-EnvHash (PPID2)
 * pageOpts.user.dnt = do not track
 */
export function getPageOptsWithExternalIds(
  brand: Brand,
  pageOpts?: PageOpts
): PageOpts | null {
  if (pageOpts) {
    pageOpts.user = {};

    const persistedPPID = (getPPID() ?? {}) as PersistedPPID;

    pageOpts.user.externalUid = persistedPPID.externalId || '';

    // Do not track
    pageOpts.user.dnt =
      persistedPPID.externalId === 'DO_NOT_TRACK' ||
      (navigator && navigator.doNotTrack === '1');

    try {
      // Remove undefined id's
      const extIds = getExtIdsWithBrand(
        brand,
        persistedPPID.ppid1,
        persistedPPID.ppid2
      );
      const filteredEids = extIds.filter((d) => Boolean(d.id));

      pageOpts.user.userIds = [
        {
          type: 'extendedIDs',
          eids: filteredEids
        }
      ];

      // Also set keywords for v3 request
      const ppidKeywords = {
        'aa-sch-schuserhash': persistedPPID.ppid1 ? ['1'] : ['0'],
        'aa-sch-schenvhash': persistedPPID.ppid2 ? ['1'] : ['0']
      };

      pageOpts.keywords = {
        ...pageOpts.keywords,
        ...ppidKeywords
      };
    } catch (e) {
      console.error(e);
    }
  }
  return null;
}

export function getPageOptsWithConsentKeyword(
  pageOpts: PageOpts,
  consentStatus: PermissionValue
): PageOpts | undefined {
  return {
    ...pageOpts,
    keywords: {
      ...pageOpts.keywords,
      'aa-sch-cmp_advertising': [consentStatus === null ? '0' : consentStatus]
    }
  };
}
