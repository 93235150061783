import React, { useEffect, useState } from 'react';

import {
  debugLog,
  PlacementId,
  UNLEASH_FEATURE_NAME
} from '@schibsted-nmp/advertising-shared';
import { advtPerformance } from '@schibsted-nmp/advertising-events';
import { getAdConfig, isFeatureEnabled } from '@client/core/state/reducer';

export const useRemoveUtifSizes = (targetId: string) => {
  // Hack to fix Relevant Digital & Xandr size bug
  useEffect(() => {
    const id = `div_utif_${targetId}`;
    const utifDiv = document.getElementById(id);
    if (utifDiv) {
      debugLog(
        `[SIZE_CHANGE] ${targetId} Removing utifDiv styles for ${targetId}`
      );
      // remove all utifDiv styles
      utifDiv.style.height = '';
      utifDiv.style.width = '';
    }
  });
};

type Sizes = {
  height: number;
  width: number;
};

export const useIframeSizeForAdContainer = (
  ref: React.RefObject<HTMLDivElement>,
  placementId: PlacementId,
  initialSizes: Sizes
): {
  height?: number;
  width?: number;
} => {
  const { brand } = getAdConfig();

  const [sizes, setSizes] = useState<Sizes>(initialSizes);

  useEffect(() => {
    // Check if MutationObserver is supported
    if (
      brand === 'finn' ||
      typeof MutationObserver === 'undefined' ||
      !isFeatureEnabled(UNLEASH_FEATURE_NAME.iframeHeightOnBanners)
    ) {
      if (typeof MutationObserver === 'undefined') {
        console.warn('MutationObserver is not supported by your browser.');
      }
      return () => {};
    }
    function setSizeFromIframe(
      iframe: HTMLIFrameElement,
      attribute: 'width' | 'height'
    ) {
      if (iframe) {
        const iframeSize = iframe.getAttribute(attribute);
        const initialSize =
          attribute === 'width' ? initialSizes.width : initialSizes.height;
        const newSize = iframeSize ? parseInt(iframeSize, 10) : initialSize;
        if (!Number.isNaN(newSize) && newSize !== initialSize) {
          advtPerformance.markXandrEvents(
            `[SIZE_CHANGE] ${placementId} Setting ${attribute} on ad from ${initialSize}px to ${newSize}px`
          );
        }
        setSizes((prev) => ({ ...prev, [attribute]: newSize }));
      }
    }

    let iframeSizeObserver: MutationObserver | null = null;
    let iframeObserver: MutationObserver | null = null;
    let iframe: HTMLIFrameElement | null = null;

    iframeSizeObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && iframe) {
          if (mutation.attributeName === 'height') {
            setSizeFromIframe(iframe, 'height');
          } else if (mutation.attributeName === 'width') {
            setSizeFromIframe(iframe, 'width');
          }
        }
      });
    });

    iframeObserver = new MutationObserver(() => {
      if (ref.current) {
        const [firstIframe] = ref.current.getElementsByTagName('iframe') || [];
        if (firstIframe) {
          iframe = firstIframe;
          // Set default height
          setSizeFromIframe(iframe, 'height');
          setSizeFromIframe(iframe, 'width');
          // Add null check before using observer
          if (iframeSizeObserver) {
            iframeSizeObserver.observe(iframe, {
              attributes: true,
              attributeFilter: ['height', 'width']
            });
          }
        }
      }
    });

    if (ref.current) {
      iframeObserver.observe(ref.current, {
        childList: true,
        subtree: true
      });
    }
    return () => {
      if (iframeSizeObserver) {
        iframeSizeObserver.disconnect();
      }
      if (iframeObserver) {
        iframeObserver.disconnect();
      }
    };
  }, [ref.current]);

  /**
   * We want to return size 0 as that means the ad should not be visible. We also
   * want to return most sizes above 10. If they are between 1 and 10, we want to return
   * undefined so that we don't set the container size to the native/video sizes: 1x1, 1x2, 2x2, 3x3 etc.
   *
   * @param size
   */
  function getSizeIfValid(size: number) {
    return size > 0 && size < 11 ? undefined : size;
  }

  return {
    height: brand === 'finn' ? undefined : getSizeIfValid(sizes.height),
    width: getSizeIfValid(sizes.width)
  };
};
