/**
 * This file is setting up a bunch of unknown config. The reason I port this over
 * to the new advertising-podlet is because we are moving away from both
 * Xandr and @sch-inventory/ad-takeover/dist/ad-takeover this coming fall.
 *
 * Therefore, I can leave this here for now instead, and then remove it as part
 * of the migration to Google Ad Manager.
 * @param apntag
 * @param placementId
 */
export function injectTakeoverTemplate(
  apntag: Record<string, any>,
  placementId = 'board_4'
) {
  function changeContent(originalContent?: string) {
    if (originalContent) {
      try {
        let content = programmatic(originalContent);
        content = googleAds(content);
        content = adformAds(content);
        content = deltaAds(content);
        content = snoapAds(content);
        content = kreateamAds(content);

        return content;
      } catch (e) {
        console.error(e);
      }
    }
    return originalContent;
  }

  function programmatic(content: string): string {
    try {
      const container: HTMLDivElement = document.createElement('div');
      container.innerHTML = content;
      const img = container.querySelector('img');
      if (img) {
        const style: HTMLStyleElement = document.createElement('style');
        style.innerText =
          '.banner {width: 100vw; height: 100vh; background-repeat: no-repeat; background-position: center; background-size: cover;}';
        const div: HTMLDivElement = document.createElement('div');
        div.classList.add('banner');
        div.style.backgroundImage = `url(${img.src})`;
        const parent = img.parentNode;
        if (parent) {
          parent.replaceChild(div, img);
        }
        container.appendChild(style);
        return container.innerHTML;
      }
    } catch (e) {
      console.error(e);
    }
    return content;
  }

  function googleAds(content: string): string {
    try {
      if (isSnoap(content)) {
        // some snoap ads have invalid html and can't be parsed
        return content;
      }

      const container: HTMLDivElement = document.createElement('div');
      container.innerHTML = content;
      const style: HTMLStyleElement = document.createElement('style');
      style.innerText =
        '.GoogleActiveViewClass, .GoogleActiveViewElement {transform: translate(calc(-50% + 50vw), 0);} .GoogleActiveViewClass img, .GoogleActiveViewElement img {width: 177vh; height: 100vh}';
      container.appendChild(style);
      return container.innerHTML;
    } catch (e) {
      console.error(e);
    }
    return content;
  }

  function adformAds(content: string): string {
    try {
      if (!isAdform(content)) {
        return content;
      }
      const container: HTMLDivElement = document.createElement('div');
      container.innerHTML = content;
      const script: HTMLScriptElement = document.createElement('script');
      script.innerText =
        'var frame = window.frameElement; ' +
        'if (frame) {' +
        ' frame.style.removeProperty("width"); ' +
        ' frame.style.removeProperty("height"); ' +
        ' frame.contentWindow.document.body.onload = onLoad;' +
        '} else {' +
        ' document.body.onload = onLoad;' +
        '}' +
        'function onLoad() {' +
        ' var iframe = document.querySelector("adfm-ad iframe");' +
        ' if (iframe) { ' +
        '   var image = iframe.contentWindow.document.getElementById("image");' +
        '   if (!image) return;' +
        '   image.style.backgroundSize = "cover";' +
        ' } else {' +
        '   var iframe = document.querySelector(".adform-adbox iframe");' +
        '   if (iframe) {' +
        '     var style = document.createElement("style");' +
        '     style.innerText = "#banner {width: 100% !important; height: 100% !important;} #banner img {object-fit: cover;}";' +
        '     iframe.contentDocument.body.append(style);' +
        '     iframe.style = "width: 100vw; height: 100vh;";' +
        '   } else {' +
        '   var observer = new MutationObserver(function(mutations, obs) {' +
        '     for (var mutation of mutations) {' +
        '       var iframe = mutation.addedNodes[0];' +
        '       if (iframe && iframe.nodeName === "IFRAME") {' +
        '         iframe.style = "width: 100vw; height: 100vh;";' +
        '         iframe.dataset.contents += "<style>#banner {width: 100% !important; height: 100% !important;} #banner img {object-fit: cover;}</style>";' +
        '         obs.disconnect();' +
        '       }' +
        '     }' +
        '   });' +
        '   observer.observe(document.body, {childList: true, subtree: true});' +
        '   }' +
        ' }' +
        '}';
      const style: HTMLStyleElement = document.createElement('style');
      style.innerText =
        'html, body, adfm-ad, #sf_align, .adform-adbox, .adform-adbox img {width: 100% !important; height: 100% !important; object-fit: cover;}';
      container.appendChild(style);
      container.appendChild(script);
      return container.innerHTML;
    } catch (e) {
      console.error(e);
    }
    return content;
  }

  function isAdform(content: string): boolean {
    return content.indexOf('adform') > -1;
  }

  function deltaAds(content: string): string {
    try {
      if (!isDelta(content)) {
        return content;
      }
      const scaleAndCenter: string =
        `var f=document.querySelector('iframe');` +
        `if(!f)return;` +
        `var s=f.style;` +
        `s.transform='scale('+(window.innerHeight/Number(f.height)).toFixed(2)+')';` +
        `s.right=parseInt((Number(f.width)-window.innerWidth)/2)+'px';` +
        `s.bottom=parseInt((Number(f.height)-window.innerHeight)/2)+'px';` +
        `s.position='relative';`;
      return content.replace('<iframe ', `<iframe onload="${scaleAndCenter}" `);
    } catch (e) {
      console.error(e);
    }
    return content;
  }

  function isDelta(content: string): boolean {
    return content.indexOf('de17a') > -1;
  }

  function snoapAds(content: string): string {
    try {
      if (!isSnoap(content)) {
        return content;
      }
      content = content.replace('<html>', '<html style="height: 100%;">');
      content = content.replace(
        '</iframe>',
        '</iframe><style>#sf_align {height: 100%;}</style>'
      );
      content = content.replace('<body style="', '<body style="height: 100%; ');
    } catch (e) {
      console.error(e);
    }
    return content;
  }

  function isSnoap(content: string): boolean {
    return content.indexOf('snoap.no') > -1;
  }

  function kreateamAds(content: string): string {
    try {
      if (!isKreateam(content)) {
        return content;
      }
      content = content.replace('<html>', '<html style="height: 100%;">');
      content = content.replace('<body style="', '<body style="height: 100%; ');
    } catch (e) {
      console.error(e);
    }
    return content;
  }

  function isKreateam(content: string): boolean {
    return content.indexOf('kreateam') > -1;
  }

  function getContent(tagName: string) {
    return apntag?.requests?.tags[tagName]?.adResponse?.ads[0]?.rtb?.banner
      ?.content;
  }

  function setContent(tagName: string, content?: string) {
    if (content) {
      try {
        apntag.requests.tags[tagName].adResponse.ads[0].rtb.banner.content =
          content;
      } catch (e) {
        // no action
      }
    }
  }

  setContent(placementId, changeContent(getContent(placementId)));
}
