import { messageBus, events } from '@schibsted-nmp/advertising-events';
import {
  AdConfigPlacement,
  AdConfigServer
} from '@schibsted-nmp/advertising-shared';

type CompanionReadyPlacementListenerProps = {
  placementsWithIntermingle: AdConfigPlacement[];
  afsPlacements: AdConfigPlacement[];
  afsConfig?: AdConfigServer['afs'];
};

export function companionReadyPlacementListener({
  placementsWithIntermingle,
  afsPlacements,
  afsConfig
}: CompanionReadyPlacementListenerProps) {
  function publishPodletReady() {
    messageBus.publish(
      events.PODLET.channel,
      events.PODLET.PODLET_READY.topic,
      events.PODLET.PODLET_READY.sendPayload({
        placementsWithIntermingle
      })
    );
    if (afsConfig) {
      messageBus.publish(
        events.PODLET.channel,
        events.PODLET.AFS_CONFIG.topic,
        events.PODLET.AFS_CONFIG.sendPayload({
          afsConfig,
          afsPlacements
        })
      );
    }
  }

  // Initial in case of early publish
  publishPodletReady();
}
