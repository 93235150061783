import React, { useEffect, useMemo, useState, FC } from 'react';
import ReactDOM from 'react-dom';

import { ConsentStatus, Feature } from '@schibsted-nmp/advertising-shared';
import { getState } from '@client/core/state/reducer';

const Tooltip = ({ text }) => {
  const [visible, setVisible] = useState(false);
  const [coords, setCoords] = useState({ x: 0, y: 0 });

  const trackMousePosition = (e) => {
    setCoords({ x: e.pageX + 15, y: e.pageY + 15 });
  };

  useEffect(() => {
    if (visible) {
      document.addEventListener('mousemove', trackMousePosition);
    } else {
      document.removeEventListener('mousemove', trackMousePosition);
    }

    return () => {
      document.removeEventListener('mousemove', trackMousePosition);
    };
  }, [visible]);

  return (
    <>
      <div
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
        >
          <circle cx="12" cy="12" r="10"></circle>
          <path d="M12 16L12 12"></path>
          <path d="M12 8L12.01 8"></path>
        </svg>
      </div>
      {visible &&
        ReactDOM.createPortal(
          <div
            style={{
              zIndex: 100,
              position: 'absolute',
              left: `${coords.x}px`,
              top: `${coords.y}px`
            }}
            className="border-2 rounded-4 p-4 s-bg whitespace-nowrap"
          >
            {text}
          </div>,
          document.body
        )}
    </>
  );
};

interface CheckboxProps {
  id: string;
  checked: boolean;
  onChange: () => void;
  tooltip?: string;
  children: string | React.ReactNode;
}

const Checkbox: FC<CheckboxProps> = ({
  children,
  id,
  checked,
  onChange,
  tooltip = ''
}) => (
  <div className="flex items-center gap-12">
    <input id={id} type="checkbox" checked={checked} onChange={onChange} />

    <label className="flex" htmlFor={id}>
      {children} {tooltip ? <Tooltip text={tooltip} /> : null}
    </label>
  </div>
);

const defaultAdId = '21306';

const DevPanel = () => {
  const { config, initialState } = getState();
  const placements = config?.placements;
  const url = new URL(window.location.href);
  const [hidePanel, setHidePanel] = useState(false);
  const [enabledFeatureFlags, setEnabledFeatureFlags] = useState<Feature[]>([]);
  const consent = initialState?.config.consent;
  const errorList = useMemo(
    () =>
      [
        placements?.length === 0
          ? "No advertising loaded because server didn't return any placement"
          : null
      ].filter((e) => !!e),
    []
  );

  useEffect(() => {
    const sP = url.searchParams;
    const _pageType = sP.get('pageType');
    const _finnKode = sP.get('finnKode');
    if (_pageType === 'object' && !_finnKode) {
      toggleSearchParamAndReload('finnKode', defaultAdId, { noDelete: true });
    }
    const { unleashFeatures } = (window as any).ADVT.state.initialState;
    const enabledFeatureKeys = Object.keys(unleashFeatures)
      .filter((key) => unleashFeatures[key].enabled)
      .map((key) => unleashFeatures[key]);
    setEnabledFeatureFlags(enabledFeatureKeys);
  }, []);

  const toggleSearchParamAndReload = (
    key: string,
    value: string,
    options: {
      noDelete?: boolean;
    } = {}
  ) => {
    if (!options.noDelete && url.searchParams.get(key)) {
      url.searchParams.delete(key);
    } else {
      url.searchParams.set(key, value);
    }
    window.location.href = url.href;
  };

  const showPlacement = (placementId: string) => {
    const el = document.getElementById(placementId);
    if (el) {
      console.log(`Found ${placementId}. Scrolling to it!`);
      el.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.log(
        `No placement with id ${placementId}. Could it be a NO BID or another error?`
      );
    }
  };

  return (
    <div
      className="fixed top-0 left-0 border border-2 flex flex-col p-2 gap-8 s-bg z-30 overflow-auto max-h-screen"
      style={{ resize: 'both', overflow: 'auto' }}
    >
      <button
        className="text-12"
        style={{ width: 40 }}
        onClick={() => setHidePanel(!hidePanel)}
      >
        {hidePanel ? 'Show' : 'Hide'}
      </button>

      {hidePanel ? null : (
        <>
          <div className="flex flex-col gap-4">
            <div>
              Member: {initialState?.config?.adServer.xandr?.pageOpts.member}
              <Tooltip text="Xandr test ID: 10390 and production ID: 10199" />
            </div>
            <div>Brand: {initialState?.config?.brand}</div>
            <div>Device: {initialState?.config?.deviceType}</div>
            <div>PageType: {initialState?.config?.pageType}</div>
          </div>
          <Checkbox
            id="ast_debug"
            checked={Boolean(url.searchParams.get('ast_debug'))}
            onChange={() => toggleSearchParamAndReload('ast_debug', 'true')}
            tooltip="Sets AST debug mode to true. In debug mode, developer console output is available."
          >
            AST Debug
          </Checkbox>
          <Checkbox
            id="advt_debug"
            checked={Boolean(url.searchParams.get('advt_debug'))}
            onChange={() => toggleSearchParamAndReload('advt_debug', 'true')}
            tooltip="Sets ADVT debub mode to true. In debug mode, developer console output is available."
          >
            ADVT Debug
          </Checkbox>
          <Checkbox
            id="ast_dongle"
            checked={Boolean(url.searchParams.get('ast_dongle'))}
            onChange={() => toggleSearchParamAndReload('ast_dongle', 'yellow')}
          >
            AST Console
          </Checkbox>

          {!!errorList?.length && <h5>Errors:</h5>}
          <ul className="text-14 flex flex-col gap-4" style={{ maxWidth: 200 }}>
            {errorList.map((errorMsg) => (
              <li key={errorMsg} className="s-bg-primary p-4 break-all">
                {errorMsg}
              </li>
            ))}
          </ul>
          {!!placements?.length && <h5>Placements:</h5>}
          <ul className="flex flex-col gap-2">
            {placements?.map((placement) => (
              <li
                key={placement?.placementId}
                id={`${placement?.placementId}-local-entry`}
                onClick={() => showPlacement(placement?.placementId)}
                className="cursor-pointer select-none rounded-4 s-bg-positive-subtle-active p-4 break-all"
              >
                {placement?.placementId}
              </li>
            ))}
          </ul>
          <div className="flex justify-between items-center mb-2">
            <h5 className="mb-0">Consent for advertising:</h5>
          </div>
          {consent === ConsentStatus.accepted && (
            <p className="text-center s-bg-positive-subtle">Accepted</p>
          )}
          {consent === ConsentStatus.rejected && (
            <p className="text-center s-bg-warning-subtle">Rejected</p>
          )}
          {enabledFeatureFlags.length === 0 ? (
            <div>No enabled feature flags</div>
          ) : (
            <div>
              Enabled feature flags:
              {enabledFeatureFlags.map((feature) => (
                <p
                  style={{
                    background: '#AFE1AF',
                    padding: '1px 4px',
                    borderRadius: 4,
                    width: 'fit-content'
                  }}
                >
                  {feature.feature}
                </p>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DevPanel;
