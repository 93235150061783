import React from 'react';

import { createRoot } from 'react-dom/client';

import { advtPerformance } from '@schibsted-nmp/advertising-events';
import { UNLEASH_FEATURE_NAME } from '@schibsted-nmp/advertising-shared';
import { ReactGamForecastingPlacement } from '@client/adManager/ReactGamForecastingPlacement';

import { AdSwitchComponent, AdSwitchProps } from './adSwitch/AdSwitchComponent';
import { isFeatureEnabled } from '../../core/state/reducer';
import { RenderContentMarketingOrChildren } from '../../contentMarketing/RenderContentMarketingOrChildren';
import { shouldRenderContentMarketing } from '../../contentMarketing/shouldRenderContentMarketing';

export function renderAd(props: AdSwitchProps) {
  advtPerformance.markXandrEvents(`renderAd`, props.containerId);

  const element = document.getElementById(props.containerId);
  const placementElement = element !== null && createRoot(element);
  if (placementElement) {
    const forecastingEnabled = isFeatureEnabled(
      UNLEASH_FEATURE_NAME.enableGamForecasting
    );

    const Children = (
      <>
        {forecastingEnabled && <ReactGamForecastingPlacement {...props} />}
        <AdSwitchComponent {...props} />
      </>
    );

    if (shouldRenderContentMarketing(props.placementId)) {
      placementElement.render(
        <RenderContentMarketingOrChildren {...props}>
          {Children}
        </RenderContentMarketingOrChildren>
      );
    } else {
      placementElement.render(Children);
    }
  }
}
