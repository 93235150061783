import { throttle } from 'throttle-debounce';

import { events, messageBus } from '@schibsted-nmp/advertising-events';

const FILTER_LIST_ID = 'advertising-tori-filters-bottom-marker';
const MIDDLE_2_ADVT_COMPONENT = 'advt-component[placementid="advt_middle_2"]';
const BANNERS_STICKY_CONTAINER_CLASS_NAME_QUERY = '.banners__sticky-container';
const BANNER_WALLPAPER_DIV = '.adn_wallpaper_1';
const FOOTER_ELEM_QUERY = 'finn-footer';
const offset = 100;

let filterListElement: HTMLElement | null = null;
let wallpaperElement: HTMLElement | null = null;
let bannerStickyContainers: NodeListOf<HTMLElement> | null = null;
let scrollListenerInitiated = false;

type Placement = {
  element: HTMLElement | null;
  id: string;
};

const adnamiPlacements: Placement[] = [{ id: 'footer', element: null }];

const storeElementByPlacementId = (placementId: string) => {
  if (placementId && !adnamiPlacements.some((p) => p.id === placementId)) {
    adnamiPlacements.push({ id: placementId, element: null });
  }
};

const fetchElement = (query: string): HTMLElement | null =>
  document.querySelector(query);
const fetchAllElements = (query: string): NodeListOf<HTMLElement> =>
  document.querySelectorAll(query);
const fetchElementById = (id: string) => document.getElementById(id);

const fetchRightStickyContainer = () => {
  if (!bannerStickyContainers) {
    bannerStickyContainers = fetchAllElements(
      BANNERS_STICKY_CONTAINER_CLASS_NAME_QUERY
    );
  }
};

const fetchFilterListElement = () => {
  if (!filterListElement) {
    filterListElement = fetchElementById(FILTER_LIST_ID);
  }
};
const fetchWallpaperElement = () => {
  if (!wallpaperElement) {
    wallpaperElement = fetchElementById(BANNER_WALLPAPER_DIV);
  }
};

const fetchAdnamiPlacements = () => {
  adnamiPlacements.forEach((placement) => {
    if (!placement.element) {
      placement.element =
        placement.id === 'footer'
          ? document.querySelector(FOOTER_ELEM_QUERY)
          : fetchElementById(placement.id);
    }
  });
};

const setBannerStickyContainerHeight = (stopperElement: Element) => {
  if (!bannerStickyContainers) return;
  const newHeight =
    stopperElement.getBoundingClientRect().top + window.scrollY - offset;
  bannerStickyContainers.forEach((container) => {
    container.style.height = `${newHeight}px`;
  });
};

const getMostTopAdnamiPlacement = () =>
  adnamiPlacements
    .filter((p) => p?.element)
    .sort(
      (a, b) =>
        a.element!.getBoundingClientRect().top -
        b.element!.getBoundingClientRect().top
    )[0]?.element || null;

const handlePositionOverlapOnScroll = () => {
  fetchRightStickyContainer();
  fetchFilterListElement();
  fetchWallpaperElement();
  fetchAdnamiPlacements();
  const firstCollisionElement = getMostTopAdnamiPlacement();

  if (firstCollisionElement) {
    setBannerStickyContainerHeight(firstCollisionElement);
  }
};

const listenToAdnamiPlacements = () => {
  messageBus.subscribe(events.PODLET.channel, 'adnami', (e) => {
    storeElementByPlacementId(e.payload as string);
    initiateIfAdnami();
  });
};

const initiateIfAdnami = () => {
  if (adnamiPlacements.length) {
    if (!scrollListenerInitiated) {
      window.addEventListener(
        'scroll',
        throttle(200, handlePositionOverlapOnScroll),
        { passive: true }
      );
      scrollListenerInitiated = true;
    }
  }
};

export const initiate = () => {
  listenToAdnamiPlacements();
  initiateIfAdnami();
  handlePositionOverlapOnScroll();
};

export const publishAdnamiPlacement = (placementId: string) => {
  messageBus.publish(events.PODLET.channel, 'adnami', placementId);
};

export const middle2OverlapsWithFilter = () => {
  fetchFilterListElement();
  if (!filterListElement) return false;

  const filterBottomPlacement =
    filterListElement.getBoundingClientRect().bottom + window.scrollY;

  const middle2 = fetchElement(MIDDLE_2_ADVT_COMPONENT);
  if (!middle2) return null;

  const middle2PlacementTop =
    middle2.getBoundingClientRect().top + window.scrollY;
  const margin = 100;

  const filterBottomPlacementWithMargin = filterBottomPlacement + margin;

  return middle2PlacementTop < filterBottomPlacementWithMargin;
};
