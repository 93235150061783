import { RefObject, useEffect } from 'react';

import {
  AdObj,
  AdStatus,
  NativeAdObj,
  PlacementId
} from '@schibsted-nmp/advertising-shared';
import { advtPerformance } from '@schibsted-nmp/advertising-events';
import { onAdLoaded, pushToAnqSafe, showTag } from '@client/xandr/xandr';
import { getInViewSettingsByPlacementId } from '@client/core/utils/getInViewSettingsByPlacementId';

import { useInScreen } from './useInScreen';

export function useLoadNativeAd(
  adObj: NativeAdObj,
  targetId: string,
  status: AdStatus
) {
  pushToAnqSafe(() => {
    showTag(targetId);
  });

  useEffect(() => {
    /* eslint-disable consistent-return */
    pushToAnqSafe(() => {
      window.apntag.attachClickTrackers(adObj, adObj.targetId);

      if (status === 'loaded') {
        window.apntag.fireImpressionTrackers(adObj);
      } else {
        onAdLoaded(targetId, (adObj) => {
          window.apntag.fireImpressionTrackers(adObj);
        });
        return () => {
          pushToAnqSafe(() => {
            ['adLoaded'].forEach((event) => {
              window.apntag.offEvent(event, targetId);
            });
          });
        };
      }
    });
  }, [targetId]);
}

type Props = {
  targetId: string;
  placementId: PlacementId;
  adObj: AdObj;
  ref: RefObject<HTMLElement>;
};

export function useLoadBannerAd(props: Props) {
  const { ref, placementId, targetId, adObj } = props;
  const { threshold, rootMargin } = getInViewSettingsByPlacementId(
    placementId,
    adObj
  );

  const { hasIntersected } = useInScreen({ ref, threshold, rootMargin });

  useEffect(() => {
    if (hasIntersected) {
      advtPerformance.markXandrEvents(
        `Preload banner ad ${placementId} has intersected with rootMargin ${rootMargin} and threshold ${threshold}`
      );
      showTag(targetId);
    }
  }, [hasIntersected]);
}
