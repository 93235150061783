import { Native } from '@schibsted-nmp/advertising-shared';

export function escapeString(str: string) {
  return decodeURIComponent(str);
}
function isNonEmptyString(s: string) {
  return s && s.length > 0;
}
export function getDescription(native: Native) {
  let description: string;

  if (isNonEmptyString(native.body)) {
    // v3
    description = native.body;
  } else if (isNonEmptyString(native.fullText)) {
    // v2
    description = native.fullText;
  } else if (isNonEmptyString(native.description)) {
    // v2
    description = native.description;
  } else {
    description = '';
  }

  return escapeString(description);
}
