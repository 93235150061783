import React, { useRef } from 'react';

import { BannerAdObj, PlacementId } from '@schibsted-nmp/advertising-shared';
import { useLoadBannerAd } from '@client/core/hooks';

import {
  useIframeSizeForAdContainer,
  useRemoveUtifSizes
} from './useSizeHacks';
import { useIsBannerAdnamiAd } from './useBannerIsAdnami';

type Props = {
  targetId: string;
  placementId: PlacementId;
  adObj: BannerAdObj;
  className: string;
};

export default function BannerAdComponent(props: Props) {
  const { placementId, targetId, adObj, className } = props;
  const ref = useRef<HTMLDivElement>(null);

  const sizes = useIframeSizeForAdContainer(ref, placementId, {
    width: adObj.width,
    height: adObj.height
  });
  useRemoveUtifSizes(targetId);

  useLoadBannerAd({ placementId, targetId, ref, adObj });
  const isAdnami = useIsBannerAdnamiAd(ref, targetId, adObj);

  return (
    <div
      style={sizes}
      className={className}
      data-is-adnami={isAdnami}
      ref={ref}
      id={targetId}
    />
  );
}
