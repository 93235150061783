import { html } from 'lit';

import { triggerAfsRequest } from '@client/adsense';
import { AdVendor } from '@client/core/AdVendor';
import { getAdConfig } from '@client/core/state/reducer';
import { events, messageBus } from '@schibsted-nmp/advertising-events';
import {
  AdConfigPlacement,
  AdConfigServer,
  AdServer,
  AfsPlacementConfig,
  AfsQuery,
  PlacementId,
  debugLog
} from '@schibsted-nmp/advertising-shared';

export class AfsAdVendor extends AdVendor {
  placementId: PlacementId;

  /**
   * This data is sent down as a param from the Podium Layout Server, and is used to
   * determine the query to be used for the AFS request. It will be replaced by server values
   * eventually.
   */
  afsQuery?: AfsQuery;

  constructor(placementId: PlacementId, afsQuery?: AfsQuery) {
    super();
    this.adServer = AdServer.AFS;
    this.placementId = placementId;
    this.afsQuery = afsQuery;

    if (this.afsQuery) {
      this.triggerFinnAfsRequest();
    }
  }

  cleanupEvents(): void {}

  setupEvents(): void {
    const peekEvent = messageBus.peek(
      events.PODLET.channel,
      events.PODLET.AFS_CONFIG.topic
    );

    if (peekEvent) {
      const { afsConfig, afsPlacements } =
        events.PODLET.AFS_CONFIG.getPayload(peekEvent);
      if (afsConfig) {
        this.triggerAfsRequest({ afsConfig, placements: afsPlacements });
      }
    }

    this.cleanupEvents = messageBus.subscribe(
      events.PODLET.channel,
      events.PODLET.AFS_CONFIG.topic,
      (event) => {
        const { afsPlacements, afsConfig } =
          events.PODLET.AFS_CONFIG.getPayload(event);
        if (afsConfig) {
          this.triggerAfsRequest({ afsConfig, placements: afsPlacements });
        }
      }
    );
  }

  getQueryByPriority(q?: AfsQuery): string {
    if (!q) return '';
    if (q?.search) {
      return q.search;
    }
    if (q?.subCategory) {
      return q.subCategory;
    }
    if (q?.category) {
      return q.category;
    }
    return '';
  }

  /**
   * This method returns the configuration for AFS for FINN.no, since the FINN config provider API
   * doesn't yet support server-side values. This method should be removed once the API is updated.
   */
  triggerFinnAfsRequest() {
    const STYLE_ID = {
      DESKTOP: '5268803732',
      MOBILE: '4302767753'
    };

    const afs = {
      pubId: 'partner-pub-4700071094164059',
      query: 'search'
    };

    if (!this.afsQuery) return;
    triggerAfsRequest({
      container: this.placementId,
      query: this.getQueryByPriority(this.afsQuery),
      number: 1,
      adPage: 'search',
      pubId: afs.pubId,
      styleId:
        getAdConfig().deviceType === 'mobile'
          ? STYLE_ID.MOBILE
          : STYLE_ID.DESKTOP
    });
  }

  updated(changedProperties: Map<string | number | symbol, unknown>): void {
    if (changedProperties.has('afsQuery')) {
      this.triggerFinnAfsRequest();
    }
  }

  private triggerAfsRequest({
    afsConfig,
    placements
  }: {
    afsConfig: AdConfigServer['afs'];
    placements: AdConfigPlacement[];
  }): void {
    if (!afsConfig) return;
    const afsPlacementConfig = placements.find(
      (placement) => placement.placementId === this.placementId
    )?.adServer.config as AfsPlacementConfig;
    const styleId = afsPlacementConfig?.styleId;
    if (!styleId) {
      debugLog(`No styleId found for container: ${this.placementId}`);
      return;
    }
    const afsPageOptions = {
      adPage: afsConfig.adPage,
      pubId: afsConfig.pubId,
      query: afsConfig.query,
      styleId,
      number: 1
    };
    if (styleId) {
      triggerAfsRequest({
        container: this.placementId,
        ...afsPageOptions
      });
    }
  }

  requestAd(): void {
    // AFS is triggered by the config event listener, do nothing here
  }

  render() {
    return html`
      <slot
        name="${`${this.placementId}--slot`}"
        id="${this.placementId}"
      ></slot>
    `;
  }
}
