import React from 'react';

import { Native } from '@schibsted-nmp/advertising-shared';

import { escapeString } from '../native.helper';

type Props = {
  native: Native;
  className?: string;
  margin?: string;
  isWide?: boolean;
};
function NativeLogo(props: Props) {
  const { native, className, isWide } = props;
  let logoUrl: string;
  if (native && native.icon && native.icon.url) {
    logoUrl = escapeString(native.icon.url);
  } else {
    return null;
  }

  const altText = escapeString(native.sponsoredBy ? native.sponsoredBy : '');

  const wideOrTallStyle = isWide
    ? { width: 48, minWidth: 48 }
    : { height: 48, minHeight: 48 };

  return (
    <img
      src={logoUrl}
      className={className}
      style={wideOrTallStyle}
      alt={altText}
      width={isWide ? 48 : ''}
      height={!isWide ? 48 : ''}
    />
  );
}

export default NativeLogo;
