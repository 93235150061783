import { getAdConfig } from '../core/state/reducer';

export function shouldRenderContentMarketing(placementId: string) {
  const { brand, vertical } = getAdConfig();

  const contentMarketingEnabledVertical =
    vertical === 'realestate' || vertical === 'car';
  const contentMarketingEnabledBrand = brand === 'finn';
  const contentMarketingEnabledPlacement = placementId?.includes('board_2');
  return (
    contentMarketingEnabledVertical &&
    contentMarketingEnabledBrand &&
    contentMarketingEnabledPlacement
  );
}
