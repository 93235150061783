import { companionReadyPlacementListener } from '@client/core/utils/events/companionReadyPlacementListener';
import { searchFilterCallback } from '@client/xandr/refreshKeywords';
import { events, messageBus } from '@schibsted-nmp/advertising-events';
import {
  AdConfig,
  AdConfigPlacement,
  AdServer,
  debugLog,
  PageType
} from '@schibsted-nmp/advertising-shared';

type setupEventsProps = {
  pageType: PageType;
  config: AdConfig;
};

export function setupEvents({ pageType, config }: setupEventsProps) {
  const placementsWithIntermingle = getPlacementsWihtIntermingle(
    config.placements
  );
  debugLog('placementsWithIntermingle: ', placementsWithIntermingle);

  if (config.placements.length > 0) {
    companionReadyPlacementListener({
      placementsWithIntermingle: getPlacementsWihtIntermingle(
        config.placements
      ),
      afsConfig: config.adServer?.afs,
      afsPlacements: getAfsPlacements(config.placements)
    });
  }

  if (pageType === 'result') {
    messageBus.subscribe(
      events.SEARCH.channel,
      events.SEARCH.FILTER.topic,
      searchFilterCallback
    );
  }
}

function getPlacementsWihtIntermingle(
  placements: AdConfigPlacement[]
): AdConfigPlacement[] {
  return placements.filter(
    (placement) =>
      !!placement.intermingle?.grid && !!placement.intermingle?.list
  );
}

function getAfsPlacements(
  placements: AdConfigPlacement[]
): AdConfigPlacement[] {
  return placements.filter(
    (placement) => placement.adServer.type === AdServer.AFS
  );
}
