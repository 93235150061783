import { getState } from '@client/core/state/reducer';
import { middle2OverlapsWithFilter } from '@client/core/utils/dom/handleAdnamiOverlap';
import {
  PlacementId,
  updateKeywordsWithHideAdnami,
  updateKeywordsWithWidth,
  XandPlacement
} from '@schibsted-nmp/advertising-shared';

import {
  adSizesToPairs,
  getRightSkyscraperSizes,
  isAdnamiSize
} from './processAdSizes';

/**
 * This is for testing and will be moved to the Database OR Xandr eventually.
 *
 * It is required config for video ads.
 *
 * @param allowedFormats
 */
function getVideoTagConfig(allowedFormats: string[]) {
  if (allowedFormats.includes('video')) {
    return {
      video: {
        frameworks: [1, 2]
      },
      rendererOptions: {
        playerTechnology: ['html5'],
        adText: 'Mainos',
        showMute: true,
        showVolume: true,
        showProgressBar: true,
        disableTopBar: true,
        // autoInitialSize
        // This does not work when safeFrame is turned on
        // https://docs.xandr.com/bundle/seller-tag/page/seller-tag/set-up-on-page-tags-for-outstream-video.html#d9224e117
        // See components/video/index.tsx - getCustomAutoInitialSize()
        autoInitialSize: true,
        allowFullscreen: false,
        playVideoVisibleThreshold: 0,
        skippable: {
          videoThreshold: 10,
          videoOffset: 5,
          skipLocation: 'top-right',
          skipText: 'Videon voi pysäyttää %%TIME%%s kuluttua',
          skipButtonText: 'Sulje'
        }
      }
    };
  }
  return {};
}

export function getTagConfig(placementId: PlacementId, targetId: string) {
  const config = getState()?.config;
  const placement = config?.placements.find(
    (p) => p.placementId === placementId
  ) as XandPlacement;
  const safeSizes = placement?.adServer.config.sizes || [];
  const safeKeywords = placement?.adServer.config.keywords || {};

  if (!placement?.adServer.config.inventoryCode || safeSizes.length === 0) {
    return null;
  }

  let sizes = safeSizes.sort((a, b) => a.height - b.height);
  let keywords = safeKeywords;

  // Hide adnami middle 2 if the state is set from handleAdnamiOverlap.ts
  if (placementId === 'advt_middle_2' && middle2OverlapsWithFilter()) {
    sizes = sizes.filter((size) => !isAdnamiSize(size));
    keywords = updateKeywordsWithHideAdnami(keywords);
  }

  if (placementId === 'advt_right_1') {
    sizes = getRightSkyscraperSizes(sizes);
    keywords = updateKeywordsWithWidth(keywords, sizes);
  }
  let tagConfig = {
    targetId,
    sizes: adSizesToPairs(sizes),
    keywords,
    invCode: placement?.adServer.config.inventoryCode,
    allowedFormats: placement?.adServer.config.allowedFormats,
    native: placement?.adServer.config.native
  };

  if (config?.brand === 'tori') {
    tagConfig = {
      ...tagConfig,
      ...getVideoTagConfig(placement?.adServer.config.allowedFormats)
    };
  }
  return tagConfig;
}
