"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConsentStatus = exports.AdServer = void 0;
var AdServer;
(function (AdServer) {
    AdServer["AFS"] = "afs";
    AdServer["Xandr"] = "xandr";
    AdServer["GAM"] = "gam";
    AdServer["Adn"] = "adn";
    AdServer["ContentMarketing"] = "content-marketing";
})(AdServer || (exports.AdServer = AdServer = {}));
var ConsentStatus;
(function (ConsentStatus) {
    ConsentStatus[ConsentStatus["rejected"] = 0] = "rejected";
    ConsentStatus[ConsentStatus["accepted"] = 1] = "accepted";
})(ConsentStatus || (exports.ConsentStatus = ConsentStatus = {}));
