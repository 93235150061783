import Glimr from 'glimr-sdk';

import {
  debugLog,
  Brand,
  ConsentStatus,
  Keywords
} from '@schibsted-nmp/advertising-shared';
import localStorage from '@client/core/utils/localStorage';

const GLIMR_TAG_STORAGE_KEY = 'glimrTags';

// Only perform Glimr logic if Client Id is set.
// TODO: Unsure if Tori should use Glimr
function getClientIdByBrand(brand: Brand = 'finn') {
  if (brand === 'finn') return 'H3IYO2A4LD43YPFZIJLN';
  return '';
}

export async function loadGlimrIfPermission({
  brand,
  consent
}: {
  brand: Brand;
  consent: ConsentStatus;
}) {
  if (consent === ConsentStatus.accepted) {
    const glimrTags = await loadGlimr({ brand });
    if (glimrTags?.tags) {
      localStorage.persistValue(GLIMR_TAG_STORAGE_KEY, glimrTags?.tags);
    }
  } else {
    localStorage.clearValue(GLIMR_TAG_STORAGE_KEY);
  }
}

export function getGlimrKeywords(): Keywords {
  const geoTags = localStorage.readValue(GLIMR_TAG_STORAGE_KEY);
  if (!geoTags) {
    return {};
  }
  return {
    'no-sno-geo': geoTags,
    'no-sno-weather': geoTags
  };
}

export async function loadGlimr({ brand }: { brand: Brand }): Promise<{
  tags: string[];
  tagMappings: Record<string, unknown>;
}> {
  debugLog('Load glimr');
  return new Promise((resolve) => {
    const clientId = getClientIdByBrand(brand);
    if (!clientId) {
      resolve({ tags: [], tagMappings: {} });
    } else {
      Glimr.setTagCacheTimeInSeconds(300);
      Glimr.getTags(clientId, (tags, tagMappings) =>
        resolve({ tags, tagMappings })
      );
    }
  });
}
