import { Brand, DeviceType } from '@schibsted-nmp/advertising-shared';

export const getRelevantYieldScript = (brand: Brand, device: DeviceType) => {
  const src = getScript(brand, device);
  return {
    name: 'relevant-digital',
    src
  };
};

export function getScript(brand: Brand, device: DeviceType): string {
  const scripts = {
    tori: {
      desktop:
        'https://tori-cdn.relevant-digital.com/static/tags/651bc602dddc6589fd377f08.js',
      mobile:
        'https://tori-cdn.relevant-digital.com/static/tags/651bcc126964241ebc8d89d8.js'
    },
    finn: {
      desktop:
        'https://schibsted-cdn.relevant-digital.com/static/tags/6321ec879dc12b8b4671319b.js',
      mobile:
        'https://schibsted-cdn.relevant-digital.com/static/tags/6321ec879dc12b8b4671319b.js'
    },
    dba: {
      desktop: '',
      mobile: ''
    },
    blocket: {
      desktop: '',
      mobile: ''
    }
  };
  if (scripts[brand] && scripts[brand][device]) {
    return scripts[brand][device];
  }
  return '';
}

export function getConfigId(brand: Brand, device: DeviceType): string {
  const configs = {
    tori: {
      desktop: '651bd53d91cfa91e77e2c767',
      mobile: '651bd55c3d410c6523e2c768'
    },
    finn: {
      desktop: '6321fd9761ced1674e713277',
      mobile: '6321fd9761ced1674e713277'
    },
    blocket: {
      desktop: '',
      mobile: ''
    },
    dba: {
      desktop: '',
      mobile: ''
    }
  };
  if (configs[brand] && configs[brand][device]) {
    return configs[brand][device];
  }
  return '';
}
