import { debugLog, PersistedPPID } from '@schibsted-nmp/advertising-shared';
import localStorage from '@client/core/utils/localStorage';
import { getTracker, PuleTracker } from '@client/core//utils/pulse/pulse';
import { setPpid } from '@client/core/state/actions';

const emptyPpid = {
  ppid1: '',
  ppid2: '',
  externalId: '',
  isLoggedIn: false
};
export function getPPID(): PersistedPPID {
  const persistedPPID = localStorage.readValue('advertising-podlet.ppid');
  if (persistedPPID) {
    return persistedPPID;
  }
  return emptyPpid;
}

export async function persistPPID(): Promise<PersistedPPID> {
  const data: PersistedPPID = emptyPpid;
  try {
    // const schibstedAccountNumber = window.finnUserId || undefined;

    const tracker = await getTracker();

    const res = await getAdvertisingIdentifiers(tracker);

    debugLog(`PPID response: ${JSON.stringify(res, null, 2)}`);

    if (res?.xandr) {
      data.ppid1 = res.xandr.ppId1;
      data.ppid2 = res.xandr.ppId2;
      data.externalId = res.xandr.adId;
      data.isLoggedIn = Boolean(res.xandr.ppId1);
    }

    // Set the object on window for use in banners.tsx and other potential places
    setPpid(data);
    localStorage.persistValue('advertising-podlet.ppid', data);
    return data;
  } catch (err) {
    setPpid(data);
    localStorage.persistValue('advertising-podlet.ppid', data);
    return data;
  }
}

export function getAdvertisingIdentifiers(tracker: PuleTracker): Promise<{
  xandr: {
    ppId1: string;
    ppId2: string;
    adId: string;
  };
}> {
  return new Promise((resolve, reject) => {
    tracker.getAdvertisingIdentifiers().then(resolve).catch(reject);
  });
}
