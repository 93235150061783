import React, { useEffect, useRef } from 'react';

import { PlacementId, VideoAdObj } from '@schibsted-nmp/advertising-shared';
import { showTag } from '@client/xandr/xandr';

import { useIframeSizeForAdContainer } from '../banner/useSizeHacks';

type Props = {
  targetId: string;
  placementId: PlacementId;
  adObj: VideoAdObj;
};

export default function VideoAdComponent(props: Props) {
  const { targetId, adObj, placementId } = props;
  const ref = useRef<HTMLInputElement>(null);
  const sizes = useIframeSizeForAdContainer(ref, placementId, {
    width: adObj.width,
    height: adObj.height
  });

  // No lazy loading for video ads
  useEffect(() => {
    showTag(targetId);
  }, []);

  return (
    <div
      style={sizes}
      className="mx-auto relative w-full overflow-hidden"
      ref={ref}
      id={targetId}
    />
  );
}
