import {
  AdPlacement,
  PlacementId,
  AdObj
} from '@schibsted-nmp/advertising-shared';

/**
 * The threshold is a number between 0.0 and 1.0 inclusive, which indicates at what percentage of the target's
 * visibility the observer's callback should be executed. A threshold of 0.0 means that the callback will run
 * as soon as even one pixel is visible, and a threshold of 1.0 means that the callback will run when the entire target is visible.
 *
 * The rootMargin is a string which specifies a set of offsets to add to the root's bounding box when calculating intersections,
 * effectively shrinking or growing the area used for intersections. The syntax is similar to that of the CSS margin property,
 * and accepts values in pixels (px) or percentages (%). The values can be positive or negative, and they can be different for each side.
 * The order of the values is top, right, bottom, left.
 *
 * All placement will have different optimal settings.
 * Sometimes it might even differ per brand
 *
 * @param placementId
 */

const scrollMargins = {
  XXL: 800,
  XL: 600,
  LONG: 400,
  MEDIUM: 200,
  SHORT: 100,
  NONE: 0
};

const threshold = 0.0;

function getScrollMargin(length: number) {
  return `${length}px 0px ${length}px 0px`;
}

export function getInViewSettingsByPlacementId(
  placementId: PlacementId,
  adObj?: AdObj
) {
  if (adObj?.adType === 'video') {
    return {
      threshold,
      rootMargin: getScrollMargin(scrollMargins.XXL)
    };
  }

  switch (placementId) {
    case AdPlacement.Top2.id: {
      return {
        threshold,
        rootMargin: getScrollMargin(scrollMargins.NONE)
      };
    }
    case AdPlacement.Top1.id: {
      return {
        threshold,
        rootMargin: getScrollMargin(scrollMargins.NONE)
      };
    }
    case AdPlacement.Middle1.id: {
      return {
        threshold,
        rootMargin: getScrollMargin(scrollMargins.MEDIUM)
      };
    }
    case AdPlacement.Middle2.id: {
      return {
        threshold,
        rootMargin: getScrollMargin(scrollMargins.MEDIUM)
      };
    }
    case AdPlacement.RecircMiddle1.id: {
      return {
        threshold,
        rootMargin: getScrollMargin(scrollMargins.MEDIUM)
      };
    }
    case AdPlacement.RecircMiddle2.id: {
      return {
        threshold,
        rootMargin: getScrollMargin(scrollMargins.MEDIUM)
      };
    }
    case AdPlacement.Bottom1.id: {
      return {
        threshold,
        rootMargin: getScrollMargin(scrollMargins.MEDIUM)
      };
    }
    default: {
      return {
        threshold,
        rootMargin: getScrollMargin(scrollMargins.NONE)
      };
    }
  }
}
