import {
  debugLog,
  AdvertisingClientState,
  UnleashFeatureManager,
  AdConfig
} from '@schibsted-nmp/advertising-shared';
import { mergeKeywords } from '@client/core/utils/mergeKeywords';

import * as allActions from './actions';

const initialState: AdvertisingClientState = {
  initialState: null,
  config: null,
  banner: {},
  cycle: 0,
  ppid: { isLoggedIn: false, ppid1: '', ppid2: '', externalId: '' },
  searchParams: { current: {}, new: {} },
  performance: {
    entries: [],
    updated: false
  }
};

function reducer(state: AdvertisingClientState, action) {
  switch (action.type) {
    case 'SET_INITIAL_STATE':
      return { ...state, initialState: action.payload };
    case 'SET_CONFIG':
      return { ...state, config: action.payload };
    case 'SET_PPID':
      return { ...state, ppid: action.payload };
    case 'UPDATE_KEYWORDS': {
      return {
        ...state,
        config: {
          ...state.config,
          adServer: {
            ...state.config?.adServer,
            xandr: {
              ...state.config?.adServer.xandr,
              pageOpts: {
                ...state.config?.adServer.xandr?.pageOpts,
                keywords: mergeKeywords(
                  state.config?.adServer.xandr?.pageOpts.keywords,
                  action.payload
                )
              }
            }
          }
        }
      };
    }
    case 'SET_PAGE_OPTS': {
      return {
        ...state,
        config: {
          ...state.config,
          xandr: {
            ...state.config?.adServer.xandr,
            pageOpts: action.payload
          }
        }
      };
    }
    case 'SET_SEARCH_PARAMS':
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          ...action.payload
        }
      };
    case 'SET_CYCLE':
      return {
        ...state,
        cycle: action.payload
      };
    case 'ADD_PERFORMANCE_ENTRY':
      return {
        ...state,
        performance: {
          entries: [...state.performance.entries, action.payload],
          updated: true
        }
      };
    default:
      return state;
  }
}

let _state = { ...initialState }; // _state is a private variable that holds the current state

// getter function
export function getState(): AdvertisingClientState {
  return (_state || {}) as AdvertisingClientState;
}

export function getAdConfig(): AdConfig {
  return (getState()?.config || {}) as AdConfig;
}

let unleashFeatureManager: UnleashFeatureManager;

export function isFeatureEnabled(featureKey: string) {
  if (!unleashFeatureManager) {
    const features = getState()?.initialState?.unleashFeatures;
    if (features) {
      unleashFeatureManager = new UnleashFeatureManager(features);
    } else {
      return false;
    }
  }
  return unleashFeatureManager.isEnabled(featureKey);
}
export const actions = allActions;

// setter function
export function dispatch(action) {
  debugLog('AdvertisingClientState dispatching', action);

  _state = reducer(_state, action);

  window.ADVT = {
    ...(window.ADVT || {}),
    state: _state
  };

  debugLog('AdvertisingClientState next state', _state);
}
