import { useEffect, useState } from 'react';

import { events, messageBus } from '@schibsted-nmp/advertising-events';

/**
 * Finds the latest received cycle event
 * @param placementId
 */
function peekInitialTargetId(placementId: string) {
  const peekEvent = messageBus.peek(
    events.PODLET.channel,
    events.PODLET.CYCLE.topic
  );

  if (peekEvent) {
    const payload = events.PODLET.CYCLE.getPayload(peekEvent);

    return placementId + payload.cycle;
  }
  return placementId;
}

export function useCycleAd(placementId: string): string {
  const [placementIdCycle, setPlacementIdCycle] = useState<string>(() =>
    peekInitialTargetId(placementId)
  );

  useEffect(() => {
    const callback = (event) => {
      const payload = events.PODLET.CYCLE.getPayload(event);
      setPlacementIdCycle(placementId + payload.cycle);
    };
    const unsubscribe = messageBus.subscribe(
      events.PODLET.channel,
      events.PODLET.CYCLE.topic,
      callback
    );
    return () => {
      unsubscribe();
    };
  }, [placementId]);

  return placementIdCycle;
}
