import { useEffect, useRef, useState } from 'react';

import {
  events,
  messageBus,
  PayloadAdContainerStatus
} from '@schibsted-nmp/advertising-events';
import {
  AdObj,
  AdStatus,
  PlacementId
} from '@schibsted-nmp/advertising-shared';

/**
 * Sets styling for both placement and it's container.
 * The placement is usually rendered right after the call of this hook.
 * The container is rendered a completely different place in the DOM and needs to
 * be retrieved by ID.
 */
export function useSetAdvtComponentStyle(
  placementId: PlacementId,
  containerId: string,
  targetId: string,
  status: AdStatus,
  adObj?: AdObj
) {
  const prevPublishedPayload = useRef<PayloadAdContainerStatus>({
    containerId: '',
    status: '',
    adType: ''
  });

  useEffect(() => {
    const payload = {
      containerId: containerId || `${placementId}--container`,
      status: status || 'failed',
      // Do this cause of a xandr bug which renders banner ad before native ad
      // in case of native ads
      adType: adObj?.adType || ''
    };
    if (
      status === 'requested' ||
      (prevPublishedPayload.current.status === payload.status &&
        prevPublishedPayload.current.adType === payload.adType)
    ) {
      return;
    }

    prevPublishedPayload.current = payload;

    messageBus.publish(
      events.PODLET.channel,
      events.PODLET.AD_CONTAINER_STATUS.topic,
      events.PODLET.AD_CONTAINER_STATUS.sendPayload(payload)
    );
  }, [status, containerId, adObj, placementId, targetId]);
}

export function useGetPlacementClassName({ placementId }) {
  const [className, setClassName] = useState('relative');

  useEffect(() => {
    let newClassName = 'relative ';
    if (placementId === PlacementId.Bottom1)
      newClassName += 'ml-auto overflow-hidden';
    if (placementId.includes('middle') || placementId.includes('board_'))
      newClassName += 'mx-auto';

    setClassName(newClassName);
  }, [placementId]);

  return className;
}
