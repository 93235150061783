"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isPremiumNativeAd = exports.isTakeover = exports.isVideoAd = exports.isAdnamiAd = exports.isNativeAd = exports.isBannerAd = void 0;
function isBannerAd(adObj) {
    return adObj.adType === 'banner';
}
exports.isBannerAd = isBannerAd;
function isNativeAd(adObj) {
    return adObj && adObj.adType === 'native';
}
exports.isNativeAd = isNativeAd;
function isAdnamiAd(adObj) {
    var _a, _b, _c, _d, _e, _f;
    return (((adObj === null || adObj === void 0 ? void 0 : adObj.adType) === 'banner' && (adObj === null || adObj === void 0 ? void 0 : adObj.width) === 1 && (adObj === null || adObj === void 0 ? void 0 : adObj.height) === 2) ||
        (((_a = adObj === null || adObj === void 0 ? void 0 : adObj.banner) === null || _a === void 0 ? void 0 : _a.width) === 1 && ((_b = adObj === null || adObj === void 0 ? void 0 : adObj.banner) === null || _b === void 0 ? void 0 : _b.height) === 2) ||
        ((_d = (_c = adObj === null || adObj === void 0 ? void 0 : adObj.banner) === null || _c === void 0 ? void 0 : _c.content) === null || _d === void 0 ? void 0 : _d.includes('adnm')) ||
        ((_f = (_e = adObj === null || adObj === void 0 ? void 0 : adObj.banner) === null || _e === void 0 ? void 0 : _e.content) === null || _f === void 0 ? void 0 : _f.includes('adnami')));
}
exports.isAdnamiAd = isAdnamiAd;
function isVideoAd(adObj) {
    return adObj.adType === 'video';
}
exports.isVideoAd = isVideoAd;
function isTakeover(adObj, deviceType) {
    return (deviceType === 'mobile' &&
        adObj.adType === 'banner' &&
        adObj.source === 'rtb' &&
        !!adObj.height &&
        !!adObj.width &&
        ((adObj.height === 1080 && adObj.width === 1000) ||
            (adObj.height === 1080 && adObj.width === 1920)));
}
exports.isTakeover = isTakeover;
function isPremiumNativeAd(adObj) {
    var _a, _b, _c;
    return (((_a = adObj.native) === null || _a === void 0 ? void 0 : _a.desc2) === 'content' ||
        ((_c = (_b = adObj === null || adObj === void 0 ? void 0 : adObj.native) === null || _b === void 0 ? void 0 : _b.custom) === null || _c === void 0 ? void 0 : _c.type) === 'content');
}
exports.isPremiumNativeAd = isPremiumNativeAd;
