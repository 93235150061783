import * as React from 'react';
import { useMemo } from 'react';

import { ContentMarketing } from '@schibsted-nmp/advertising-shared';
import { asPx, textWithEllipse } from '@client/core/utils/string';

import { Props } from './NativeAdComponent';
import NativeCogwheel from './parts/NativeCogwheel';

export default function NativePremiumAdComponent({ adObj, targetId }: Props) {
  const { native } = adObj;

  const contentMarketing: ContentMarketing = useMemo(
    () => ({
      title: textWithEllipse(native.body),
      text: textWithEllipse(native.title),
      id: targetId,
      variantName: '',
      sponsor: native.sponsoredBy,
      width: asPx(native.image.width),
      imagePosition: 'top',
      href: native.clickUrl,
      image: {
        id: '',
        url: native?.image?.url || '',
        height: native?.image?.height,
        width: native?.image?.width
      },
      icon: {
        url: native?.icon?.url || native?.iconImgUrl,
        id: '',
        height: asPx(native?.icon?.height),
        width: asPx(native?.icon?.width)
      }
    }),
    [native, targetId]
  );

  return (
    <article
      id={contentMarketing.id}
      className="col-span-full h-full w-full relative"
    >
      <NativeCogwheel adObj={adObj} extraInset={false} />
      <advt-sponsored-content-teaser
        id={contentMarketing.id}
        contentMarketing={contentMarketing}
      />
    </article>
  );
}
