import pulse from '@finn-no/pulse-sdk';

export type PuleTracker = any;

export function getTracker(): Promise<PuleTracker> {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      const errMsg = 'Timeout from pulse.getTracker()';
      console.error(`[advertising-podlet] ${errMsg}`);
      reject(new Error(errMsg));
    }, 500);

    pulse.getTracker((tracker) => {
      if (tracker) {
        clearTimeout(timeout);
        resolve(tracker);
      } else {
        clearTimeout(timeout);
        const errMsg = 'No tracker found from pulse.getTracker()';
        console.error(`[advertising-podlet] ${errMsg}`);
        reject(new Error(errMsg));
      }
    });
  });
}

export function getPulseData(
  pulseFunction: (tracker: PuleTracker) => Promise<string | undefined>
): (tracker: PuleTracker) => Promise<string | undefined> {
  return (tracker: PuleTracker) =>
    new Promise((resolve, reject) => {
      pulseFunction(tracker).then(resolve).catch(reject);
    });
}

export const getLoggedInPPID = getPulseData((tracker) =>
  tracker.getXandrPPID1()
);
export const getNotLoggedInPPID = getPulseData((tracker) =>
  tracker.getXandrPPID2()
);
export const getEnvironmentId = getPulseData((tracker) =>
  tracker.getEnvironmentId()
);
