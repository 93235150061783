"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdPlacement = exports.PlacementId = void 0;
var PlacementId;
(function (PlacementId) {
    PlacementId["Empty"] = "";
    PlacementId["Top1"] = "advt_top_1";
    PlacementId["Left1"] = "advt_left_1";
    PlacementId["Left2"] = "advt_left_2";
    PlacementId["Right1"] = "advt_right_1";
    PlacementId["Right2"] = "advt_right_2";
    PlacementId["Top2"] = "advt_top_2";
    PlacementId["Middle1"] = "advt_middle_1";
    PlacementId["Middle2"] = "advt_middle_2";
    PlacementId["Bottom1"] = "advt_bottom_1";
    PlacementId["RecircMiddle1"] = "advt_recirc_middle_1";
    PlacementId["RecircMiddle2"] = "advt_recirc_middle_2";
    PlacementId["RecircMiddle3"] = "advt_recirc_middle_3";
    PlacementId["RecircMiddle4"] = "advt_recirc_middle_4";
    PlacementId["RecircMiddle5"] = "advt_recirc_middle_5";
    PlacementId["Wallpaper"] = "advt_wallpaper";
    PlacementId["AfsContainer1"] = "afs_container_1";
    PlacementId["AfsContainer2"] = "afs_container_2";
    PlacementId["Financing1"] = "advt_financing_1";
    PlacementId["Audience"] = "advt_audience";
    PlacementId["Marketing"] = "advt_marketing";
    PlacementId["Article1"] = "advt_article_1";
    PlacementId["Article2"] = "advt_article_2";
    /**
     * These are temporary as they will be phased into the same naming convention as
     * the other NMP placements.
     */
    PlacementId["FinnLegendaryTop1"] = "topboard";
    PlacementId["FinnLegendaryRight1"] = "skyscraperright_1";
    PlacementId["FinnLegendaryLeft1"] = "skyscraperleft_1";
    PlacementId["FinnLegendaryWallpaper"] = "wallpaper";
})(PlacementId || (exports.PlacementId = PlacementId = {}));
exports.AdPlacement = Object.fromEntries(Object.entries(PlacementId).map(function (_a) {
    var key = _a[0], value = _a[1];
    return [
        key,
        {
            id: value,
            containerId: "".concat(value, "--container"),
            slot: "".concat(value, "--slot")
        }
    ];
}));
