import cn from 'classnames';
import { LitElement, PropertyValues } from 'lit';

import {
  AdServer,
  LayoutType,
  PlacementId,
  debugLog,
  AdStatus,
  AdType
} from '@schibsted-nmp/advertising-shared';
import { AdSwitchProps } from '@client/xandr/components/adSwitch/AdSwitchComponent';
import { getAdConfig } from '@client/core/state/reducer';
import { AdVendor } from '@client/core/AdVendor';
import { GamAdVendor } from '@client/adManager/GamAdVendor';
import { AfsAdVendor } from '@client/adsense/AfsAdVendor';
import { XandrAdVendor } from '@client/xandr/XandrAdVendor';
import { AdnAdVendor } from '@client/adnuntius/AdnAdVendor';

import AdvtComponentStyles, {
  getClassNameByStatus,
  getClassNameByType
} from './AdvtComponentStyles';

const NAME = 'advt-component';

class AdvtComponent extends LitElement implements AdSwitchProps {
  // Properties with default values or required indicators
  containerId: string;

  placementId: PlacementId;

  adIndex?: number;

  additionalData?: object;

  initialLayoutType?: LayoutType;

  adVendorInstance: InstanceType<typeof AdVendor>;

  private _originalClassName: string;

  private _statusClassName: string;

  private _typeClassName: string;

  static get properties() {
    return {
      containerId: { type: String },
      placementId: { type: String },
      initialLayoutType: { type: String },
      adIndex: { type: Number },
      additionalData: { type: Object }
    };
  }

  constructor() {
    super();
    this.placementId = PlacementId.Empty;
    this.containerId = ``;
    this.adIndex = 0;
    this.additionalData = {};
    this._originalClassName = '';
    this._statusClassName = '';
    this._typeClassName = '';
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.adVendorInstance.cleanupEvents();
  }

  connectedCallback(): void {
    super.connectedCallback();
    this.initAdVendorInstance();

    this.adVendorInstance.setupEvents({
      requestUpdateCallback: this.requestUpdate.bind(this),
      handleAdTypeChange: (adType: AdType) => {
        this._typeClassName = getClassNameByType(adType);
        this.className = cn(
          this._originalClassName,
          this._statusClassName,
          this._typeClassName
        );
      },
      handleStatusChange: (status: AdStatus) => {
        this._statusClassName = getClassNameByStatus(status);
        this.className = cn(
          this._originalClassName,
          this._statusClassName,
          this._typeClassName
        );
      }
    });
  }

  private initAdVendorInstance() {
    const { placements } = getAdConfig();
    const placement = placements.find(
      (placement) => placement.placementId === this.placementId
    );
    if (!placement) {
      debugLog('initAdVendorInstance failed. Missing placement from config');
      return;
    }

    if (placement.adServer.type === AdServer.GAM) {
      this.adVendorInstance = new GamAdVendor(this.placementId);
      this.adVendorInstance.placementId = this.placementId;
      this.className = cn(this.className, 'advt-g');
    } else if (placement.adServer.type === AdServer.AFS) {
      this.adVendorInstance = new AfsAdVendor(this.placementId);
      this.adVendorInstance.placementId = this.placementId;
      this._originalClassName = cn(this.className, 'advt-g');
      this.className = this._originalClassName;
    } else if (placement.adServer.type === AdServer.Adn) {
      this.adVendorInstance = new AdnAdVendor(this.placementId);
      this.adVendorInstance.placementId = this.placementId;
      this.className = cn(this.className, 'advt-adn');
    } else {
      this.adVendorInstance = new XandrAdVendor(
        this.placementId,
        this.containerId || `${this.placementId}--container`,
        this.adIndex || 0,
        this.additionalData || {},
        this.initialLayoutType || 'list'
      );
      this._originalClassName = cn(this.className, 'advt-x');
      this.className = this._originalClassName;
    }
    debugLog(
      `AdvtComponent: Using ${this.adVendorInstance.adServer} for ${this.placementId}`
    );
  }

  private makeSlotAvailableFromLightDOM(placementId: string) {
    const containerId =
      this.adVendorInstance.adServer === AdServer.AFS
        ? placementId
        : `${placementId}--container`;

    if (this.querySelector(`#${containerId}`)) {
      // If the container is already in the surrounding DOM, do nothing. This
      // check makes the component backwards compatible when the slotted
      // container is added by the component user.
      //
      // This is necessary because the component is used in two ways:
      // 1. The component user adds the container to the light DOM. This is the
      //    recommended way of using the component. One reason for this is to avoid
      //    hydration mismatch issues when using the component with React SSR.
      // 2. The component user does not add the container to the light DOM.
      //    This is the legacy way of using the component. The component adds the
      //    container to the light DOM itself, in order to be backwards compatible.
      return;
    }

    const slotDiv = document.createElement('div');
    slotDiv.slot = `${placementId}--slot`;
    slotDiv.id = containerId;

    // add the div with slot available in the light DOM
    this.appendChild(slotDiv);
  }

  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);
    this.makeSlotAvailableFromLightDOM(this.adVendorInstance.placementId);
    if (
      this.adVendorInstance.adServer === AdServer.Xandr ||
      this.adVendorInstance.adServer === AdServer.GAM ||
      this.adVendorInstance.adServer === AdServer.Adn
    ) {
      this.adVendorInstance.requestAd();
    }
  }

  static styles = AdvtComponentStyles;

  render() {
    return this.adVendorInstance.render();
  }
}
export function defineAdvtComponent() {
  if (!customElements.get(NAME)) {
    customElements.define(NAME, AdvtComponent);
  }
}
