import {
  actions,
  dispatch,
  getAdConfig,
  getState
} from '@client/core/state/reducer';
import { events, messageBus } from '@schibsted-nmp/advertising-events';
import {
  AdServer,
  AfsConfig,
  Keywords,
  SearchConfigBody,
  SearchKey,
  SearchParams,
  debugLog
} from '@schibsted-nmp/advertising-shared';
import fetcher from '@client/core/utils/api/fetcher';

import { setPageOpts } from './xandr';

export type FilterUpdatePayloadType = {
  searchKey: SearchKey;
  searchParams: SearchParams;
};

type SearchConfigResponse = {
  data: {
    keywords: Keywords;
    afs?: AfsConfig;
  };
};

const refreshBanners = () => {
  const cycle = getState().cycle + 1;
  dispatch(actions.setCycle(cycle));
  messageBus.publish(
    events.PODLET.channel,
    events.PODLET.CYCLE.topic,
    events.PODLET.CYCLE.sendPayload({
      cycle
    })
  );
};

function shouldUpdateKeywords(payload: FilterUpdatePayloadType): boolean {
  // If the search params have changed, we need to update the keywords.
  // Here we can do more specific checks such as update on pagination but do not on sort.

  if (Object.entries(getState().searchParams.new).length === 0) return false;
  if (!payload.searchKey) return false;

  return getState().searchParams.current !== getState().searchParams?.new;
}

export const searchFilterCallback = async (event) => {
  try {
    const payload = event.payload as FilterUpdatePayloadType;
    debugLog(`Received search filter update:`, payload);

    dispatch(actions.setSearchParams({ new: payload.searchParams }));
    if (shouldUpdateKeywords(payload)) {
      const deviceType = getAdConfig().deviceType || 'desktop';
      const consent = getAdConfig().consent || '0';
      const body = {
        searchKey: payload.searchKey,
        searchParams: payload.searchParams,
        deviceType,
        consent
      } as SearchConfigBody;
      const { data } = await fetcher<SearchConfigResponse>(
        '/api/search-config',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        }
      );
      debugLog(`Data returned from search-config`, { data });

      if (data?.keywords) {
        dispatch(actions.updateKeywords(data.keywords));

        if (data?.keywords) {
          setPageOpts({ keywords: data?.keywords }, () => {
            refreshBanners();
          });
        }

        dispatch(actions.setSearchParams({ current: payload.searchParams }));
      }

      if (data?.afs) {
        debugLog(`Sending AFS config to AFS ad vendor`, { afs: data.afs });
        messageBus.publish(
          events.PODLET.channel,
          events.PODLET.AFS_CONFIG.topic,
          events.PODLET.AFS_CONFIG.sendPayload({
            afsConfig: data.afs,
            afsPlacements: getAdConfig().placements.filter(
              (placement) => placement.adServer.type === AdServer.AFS
            )
          })
        );
      }
    }
  } catch (error) {
    console.log(`Error in search-config API fetch: ${error}`);
  }
};
