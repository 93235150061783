"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateKeywordsWithHideAdnami = exports.updateKeywordsWithWidth = exports.getKeywordByKey = void 0;
var getKeywordByKey = function (keywords, key) {
    if (keywords) {
        return keywords[key];
    }
    return undefined;
};
exports.getKeywordByKey = getKeywordByKey;
/**
 * For right_1 placement, we need to set a definition on which widths the ad supports
 */
var updateKeywordsWithWidth = function (keywords, sizes) {
    function removeSizeIfThreeByThree(size) {
        // callback that checks if both height and width is 3, to be used by a .filter func
        return size.width !== 3 && size.height !== 3;
    }
    function formatToWidthString(size) {
        return "".concat(size.width);
    }
    keywords['nmp-right_1_required_width'] = sizes
        .filter(removeSizeIfThreeByThree)
        .map(formatToWidthString);
    return keywords;
};
exports.updateKeywordsWithWidth = updateKeywordsWithWidth;
/**
 * For middle_2 placement, we need to set a definition that states we're hiding adnami placement
 */
var updateKeywordsWithHideAdnami = function (keywords) { return (__assign(__assign({}, keywords), { 'nmp-filter_blocker': ['block_midscroll'] })); };
exports.updateKeywordsWithHideAdnami = updateKeywordsWithHideAdnami;
