"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnleashFeatureManager = void 0;
var UnleashFeatureManager = /** @class */ (function () {
    function UnleashFeatureManager(features) {
        this.features = features;
    }
    UnleashFeatureManager.prototype.isEnabled = function (featureName) {
        var _a;
        if (!this.features || Object.keys(this.features).length === 0)
            return false;
        return ((_a = this.features[featureName]) === null || _a === void 0 ? void 0 : _a.enabled) || false;
    };
    return UnleashFeatureManager;
}());
exports.UnleashFeatureManager = UnleashFeatureManager;
