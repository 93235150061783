// A Creative ID that means the ad is a mediation No Bid
import {
  PlacementId,
  UNLEASH_FEATURE_NAME
} from '@schibsted-nmp/advertising-shared';
import { isFeatureEnabled } from '@client/core/state/reducer';

const MEDIATION_NO_BID_CREATIVE = 502388194;

export function checkForNoBidCreatives(
  placementId: PlacementId,
  creativeId: number
) {
  if (isFeatureEnabled(UNLEASH_FEATURE_NAME.disableNoMediationBidHandler)) {
    return false;
  }
  if (creativeId === MEDIATION_NO_BID_CREATIVE) {
    return true;
  }

  const listOfCreativesForPlacement = (window.apntag?.requests?.tags[
    placementId
  ]?.adResponse?.ads || []) as any[];

  if (listOfCreativesForPlacement?.length) {
    return listOfCreativesForPlacement.some(
      (ad) => ad.creative_id === MEDIATION_NO_BID_CREATIVE
    );
  }
  return false;
}
