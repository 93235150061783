import { html } from 'lit';

import { events, messageBus } from '@schibsted-nmp/advertising-events';
import {
  AdServer,
  LayoutType,
  PlacementId
} from '@schibsted-nmp/advertising-shared';
import { renderAd } from '@client/xandr/components/renderAd';
import { getState } from '@client/core/state/reducer';
import { AdVendor } from '@client/core/AdVendor';

export class XandrAdVendor extends AdVendor {
  constructor(
    placementId: PlacementId,
    containerId: string,
    adIndex: number,
    additionalData: object,
    initialLayoutType: LayoutType
  ) {
    super();
    this.adServer = AdServer.Xandr;
    this.placementId = placementId;
    this.initialLayoutType = initialLayoutType;
    this.containerId = containerId;
    this.adIndex = adIndex;
    this.additionalData = additionalData;
  }

  updated(): void {}

  cleanupEvents(): void {}

  setupEvents({ handleAdTypeChange, handleStatusChange }): void {
    const handleAdContainerStatusChangeCallback = (event) => {
      const payload = events.PODLET.AD_CONTAINER_STATUS.getPayload(event);
      if (payload.containerId === this.containerId) {
        // Apply styles and classes to the host element
        handleAdTypeChange(payload.adType);
        handleStatusChange(payload.status);
      }
    };

    // check if there already are messages received
    const peekEvent = messageBus.peek(
      events.PODLET.channel,
      events.PODLET.AD_CONTAINER_STATUS.topic
    );
    if (peekEvent) {
      handleAdContainerStatusChangeCallback(peekEvent);
    }
    const unsubscribeAdStyles = messageBus.subscribe(
      events.PODLET.channel,
      events.PODLET.AD_CONTAINER_STATUS.topic,
      handleAdContainerStatusChangeCallback
    );

    this.cleanupEvents = () => {
      unsubscribeAdStyles();
    };
  }

  requestAd(): void {
    const advtState = getState();
    const config = advtState?.config;
    const configHasPlacement = config?.placements?.find(
      (p) => p.placementId === this.placementId
    );
    if (configHasPlacement) {
      renderAd({
        containerId: this.containerId,
        placementId: this.placementId,
        initialLayoutType: this.initialLayoutType
      });
    }
  }

  render() {
    return html`<slot
      name="${`${this.placementId}--slot`}"
      id="${this.containerId || `${this.placementId}--container`}"
    ></slot>`;
  }
}
