import { getState } from '@client/core/state/reducer';

export default async function fetcher<T>(
  url: string,
  options?: RequestInit
): Promise<T> {
  const baseUrl = getState().initialState?.baseUrl || '';
  const response = await fetch(`${baseUrl}${url}`, options);
  return response.json();
}
