import { Brand } from '@schibsted-nmp/advertising-shared';

const brandScripts: Omit<Record<Brand, string>, 'finn' | 'blocket'> = {
  tori: 'https://functions.adnami.io/api/macro/adsm.macro.beta.tori.fi.js',
  dba: 'https://macro.adnami.io/macro/spec/adsm.macro.ffb353e8-217d-4acd-a1d6-a598c7d15339.js'
};

export const getAdNamiScript = (brand: Brand) => {
  const script = brandScripts[brand];

  if (!script) {
    return null;
  }

  return {
    name: 'adnami',
    src: script
  };
};
