import * as React from 'react';
import { useEffect, useRef } from 'react';

import { AdTakeover } from '@sch-inventory/ad-takeover/dist/ad-takeover';
import { AdObj, PlacementId } from '@schibsted-nmp/advertising-shared';
import { getAdConfig } from '@client/core/state/reducer';
import { useLoadBannerAd } from '@client/core/hooks';

type Props = {
  placementId: PlacementId;
  targetId: string;
  adObj: AdObj;
  className?: string;
};

function TakeoverAdComponent(props: Props) {
  const { deviceType } = getAdConfig();
  const { placementId, targetId, adObj } = props;

  const ref = useRef<HTMLDivElement>(null);

  useLoadBannerAd({ targetId, placementId, adObj, ref });
  useEffect(() => {
    if (document) {
      try {
        const width = Math.max(
          document.documentElement.clientWidth,
          window.innerWidth || 0
        );

        if (window.apntag) {
          AdTakeover.create(
            targetId,
            {
              articlesContainerWidth: width,
              headerSelector: '.top-bar'
            },
            window.apntag
          );
          if (deviceType === 'mobile') {
            const body = document.getElementsByTagName('body')[0];
            if (body) body.classList.add('mobile');
          }
        }
      } catch (ex) {
        console.error(ex);
      }
    }
  }, []); // We only want this to run once

  return (
    <div ref={ref} className="relative col-span-full" id={targetId}>
      <span className="">
        <div
          id={`${targetId}-button`}
          className="ad-info"
          style={{ left: -16 }}
        />
      </span>
    </div>
  );
}

export default TakeoverAdComponent;
