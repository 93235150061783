import { html } from 'lit';

import { AdVendor } from '@client/core/AdVendor';
import { events, messageBus } from '@schibsted-nmp/advertising-events';
import {
  AdnPlacement,
  AdServer,
  PlacementId
} from '@schibsted-nmp/advertising-shared';
import { getAdConfig } from '@client/core/state/reducer';
import { renderDisplayAd } from '@client/core/batchRequesting/renderDisplayAd';
import { setupBatchRequestingForVendor } from '@client/core/batchRequesting/setupBatchRequestingForAdVendor';

export class AdnAdVendor extends AdVendor {
  placementId: PlacementId;

  constructor(placementId: PlacementId) {
    super();
    this.adServer = AdServer.Adn;
    this.placementId = placementId;
    this.containerId = `${placementId}--container`;
  }

  cleanupEvents(): void {}

  updated(): void {}

  setupEvents({ handleAdTypeChange, handleStatusChange }): void {
    const eventCallback = (event) => {
      const payload = events.PODLET.AD_CONTAINER_STATUS.getPayload(event);
      if (payload.containerId === this.containerId) {
        // Apply styles and classes to the host element
        handleAdTypeChange(payload.adType);
        handleStatusChange(payload.status);
      }
    };
    const peekEvent = messageBus.peek(
      events.PODLET.channel,
      events.PODLET.AD_CONTAINER_STATUS.topic
    );
    if (peekEvent) {
      eventCallback(peekEvent);
    }
    this.cleanupEvents = messageBus.subscribe(
      events.PODLET.channel,
      events.PODLET.AD_CONTAINER_STATUS.topic,
      eventCallback
    );
  }

  requestAd(): void {
    renderDisplayAd({
      placementId: this.placementId,
      containerId: this.containerId || `${this.placementId}--container`
    });
  }

  render() {
    return html`
      <slot name="${`${this.placementId}--slot`}" id="${this.placementId}">
        >
        <div id="${this.placementId}"></div
      ></slot>
    `;
  }

  static setupBatchRequesting() {
    const requestAds = (placements: AdnPlacement[]) => {
      const adUnits = placements.map(({ placementId }) => ({
        auId:
          placements.find((placement) => placement.placementId === placementId)
            ?.adServer.config.auId ?? '',
        targetId: placementId,
        kv: getAdConfig().adServer.adn?.kv || []
      }));

      window.adn.calls.push(() => {
        window.adn.request({
          adUnits
        });
      });
    };

    setupBatchRequestingForVendor({
      adServer: AdServer.Adn,
      requestAds
    });
  }
}
