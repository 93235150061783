import React, { ReactNode, useEffect, useState } from 'react';

import { events, messageBus } from '@schibsted-nmp/advertising-events';
import { isFeatureEnabled } from '@client/core/state/reducer';
import { UNLEASH_FEATURE_NAME } from '@schibsted-nmp/advertising-shared';

import { AdSwitchProps } from '../xandr/components/adSwitch/AdSwitchComponent';

type Props = AdSwitchProps & {
  children: ReactNode;
};

export function RenderContentMarketingOrChildren(props: Props) {
  const { children: backFillAd } = props;
  const [shouldLoad, setShouldLoad] = useState(true);

  useEffect(() => {
    messageBus.publish(
      events.PODLET.channel,
      events.PODLET.AD_CONTAINER_STATUS.topic,
      events.PODLET.AD_CONTAINER_STATUS.sendPayload({
        status: 'loaded',
        // Should share the same style as banner ads
        adType: 'banner',
        containerId: props.containerId
      })
    );
  }, [props.placementId]);

  useEffect(() => {
    function handleContentMarketingFallbackCallback() {
      setShouldLoad(false);
    }
    // check if there already are messages received
    const peekContentMarketingEvent = messageBus.peek(
      events.PODLET.channel,
      events.PODLET.CONTENT_MARKETING_NO_SOV.topic
    );
    if (peekContentMarketingEvent) {
      handleContentMarketingFallbackCallback();
    }

    const unsubscribeContentManagement = messageBus.subscribe(
      events.PODLET.channel,
      events.PODLET.CONTENT_MARKETING_NO_SOV.topic,
      handleContentMarketingFallbackCallback
    );
    return () => {
      if (unsubscribeContentManagement) {
        unsubscribeContentManagement();
      }
    };
  }, []);

  if (!shouldLoad) return backFillAd;

  const enableContentfulSovFetcher = isFeatureEnabled(
    UNLEASH_FEATURE_NAME.enableContentfulFetcher
  );

  if (enableContentfulSovFetcher) return <contentful-sov-fetcher />;
  return <advt-sponsored-content-teaser id={props.placementId} />;
}
