import {
  actions,
  dispatch,
  isFeatureEnabled
} from '@client/core/state/reducer';
import {
  debugLog,
  AdConfig,
  PermissionValue,
  UNLEASH_FEATURE_NAME,
  ConsentStatus
} from '@schibsted-nmp/advertising-shared';
import { getConsentOrSubscribe } from '@client/core/utils/consent';

import {
  getPageOptsWithConsentKeyword,
  getPageOptsWithExternalIds
} from './externalId';
import { getGlimrKeywords, loadGlimrIfPermission } from './glimr';
import { persistPPID } from './ppid';
import { setPageOpts } from './xandr';

export async function setPageOptsInXandr(config: AdConfig) {
  await Promise.all([
    persistPPID(),
    loadGlimrIfPermission({
      brand: config.brand,
      consent: config.consent || ConsentStatus.rejected
    })
  ]);

  let pageOpts;

  getConsentOrSubscribe((permissionValue: PermissionValue) => {
    if (permissionValue === '1') {
      const pageOptsWithEids = getPageOptsWithExternalIds(
        config.brand,
        config?.adServer.xandr?.pageOpts
      );
      pageOpts = {
        ...pageOptsWithEids,
        keywords: { ...pageOptsWithEids?.keywords, ...getGlimrKeywords() }
      };
    } else {
      pageOpts = config?.adServer.xandr?.pageOpts;
    }

    pageOpts = getPageOptsWithConsentKeyword(pageOpts, permissionValue);

    if (
      isFeatureEnabled(
        UNLEASH_FEATURE_NAME.enableAdditionalTestKeyValuePageOpts
      )
    ) {
      pageOpts.keywords = {
        ...pageOpts.keywords,
        'test-nmp-unleash': ['true']
      };
    }

    dispatch(actions.addPageOpts(pageOpts));

    setPageOpts(pageOpts, () => debugLog('pageOpts set', pageOpts));
  });
}
