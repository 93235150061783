import { useEffect } from 'react';

import { AdSwitchProps } from '@client/xandr/components/adSwitch/AdSwitchComponent';
import { renderDisplayAd } from '@client/core/batchRequesting/renderDisplayAd';
import { PlacementId } from '@schibsted-nmp/advertising-shared';

export const ReactGamForecastingPlacement = (props: AdSwitchProps) => {
  useEffect(() => {
    renderDisplayAd({
      containerId: props.containerId,
      placementId: `forecasting-${props.placementId}` as PlacementId
    });
  }, []);
  return null;
};
