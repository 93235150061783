import { html } from 'lit';

import { events, messageBus } from '@schibsted-nmp/advertising-events';
import {
  AdServer,
  debugLog,
  GamEventListenerEventName,
  GamPlacement,
  getSizesByMediaType,
  PlacementId,
  UNLEASH_FEATURE_NAME
} from '@schibsted-nmp/advertising-shared';
import { AdVendor } from '@client/core/AdVendor';
import { getState, isFeatureEnabled } from '@client/core/state/reducer';
import { renderDisplayAd } from '@client/core/batchRequesting/renderDisplayAd';
import { setupBatchRequestingForVendor } from '@client/core/batchRequesting/setupBatchRequestingForAdVendor';

export class GamAdVendor extends AdVendor {
  placementId: PlacementId;

  constructor(placementId: PlacementId) {
    super();
    this.adServer = AdServer.GAM;
    this.placementId = placementId;
    this.containerId = `${placementId}--container`;
  }

  cleanupEvents(): void {}

  updated(): void {}

  setupEvents({ handleAdTypeChange, handleStatusChange }): void {
    const eventCallback = (event) => {
      const payload = events.PODLET.AD_CONTAINER_STATUS.getPayload(event);
      if (payload.containerId === this.containerId) {
        // Apply styles and classes to the host element
        handleAdTypeChange(payload.adType);
        handleStatusChange(payload.status);
      }
    };
    const peekEvent = messageBus.peek(
      events.PODLET.channel,
      events.PODLET.AD_CONTAINER_STATUS.topic
    );
    if (peekEvent) {
      eventCallback(peekEvent);
    }
    this.cleanupEvents = messageBus.subscribe(
      events.PODLET.channel,
      events.PODLET.AD_CONTAINER_STATUS.topic,
      eventCallback
    );
  }

  requestAd(): void {
    renderDisplayAd({
      placementId: this.placementId,
      containerId: this.containerId || `${this.placementId}--container`
    });
  }

  render() {
    return html`
      <slot
        name="${`${this.placementId}--slot`}"
        id="${this.placementId}"
      ></slot>
    `;
  }

  static setupBatchRequesting() {
    const addEvents = () => {
      const events: GamEventListenerEventName[] = [
        'slotRequested',
        'slotRenderEnded',
        'impressionViewable',
        'slotVisibilityChanged',
        'slotOnload'
      ];
      events.forEach((eventType) => {
        window.googletag
          .pubads()
          .addEventListener(eventType, (event) =>
            debugLog(`${eventType} event:`, event)
          );
      });
    };

    const enabledForecasting = isFeatureEnabled(
      UNLEASH_FEATURE_NAME.enableGamForecasting
    );

    const requestAds = (placements: GamPlacement[]) => {
      window.googletag.cmd.push(() => {
        const definedSlots = placements.map((placement) => {
          const placementConfig = placement.adServer.config;
          const sizes =
            placementConfig.sizes ||
            getSizesByMediaType(placementConfig.mediaTypes);
          const slot = window.googletag
            .defineSlot(placementConfig.path, sizes, placement.placementId)
            .addService(window.googletag.pubads());

          placement?.adServer?.config?.targeting.forEach(({ key, value }) => {
            slot.setTargeting(key, value);
          });

          if (isFeatureEnabled(UNLEASH_FEATURE_NAME.enableGamTestCampaign)) {
            // targeting to enable testing campaigns in prod
            slot.setTargeting('gamTestCampaign', 'true');
          }

          if (getState().initialState?.env === 'local') {
            slot.setTargeting('test', 'true');
          }

          if (enabledForecasting && placement.forecasting) {
            slot.setTargeting('forecasting', 'true');
          }

          debugLog('Slot defined:', slot);
          return slot;
        });

        addEvents();

        window.googletag.pubads().enableSingleRequest();
        window.googletag.pubads().refresh(definedSlots);
      });
    };

    setupBatchRequestingForVendor({
      adServer: AdServer.GAM,
      requestAds,
      ...(enabledForecasting && { overrideRequestDebounce: 0 })
    });
  }
}
