import { AdConfigServer, debugLog } from '@schibsted-nmp/advertising-shared';

export function initiateGam(gamConfig: AdConfigServer['gam']) {
  window.googletag = window.googletag || {};
  window.googletag.cmd = window.googletag.cmd || [];

  // Push all configurations into a single command block -> or else signle request wont work
  window.googletag.cmd.push(() => {
    window.googletag.pubads().enableSingleRequest();
    window.googletag.pubads().collapseEmptyDivs(true);
    window.googletag.pubads().disableInitialLoad();

    // Set global targeting
    if (gamConfig?.targeting) {
      gamConfig.targeting.forEach((target) => {
        window.googletag.pubads().setTargeting(target.key, target.value);
      });
    }

    // Enable services now that everything is set up
    window.googletag.enableServices();

    debugLog('Google Ad Manager initialized', window.googletag, gamConfig);
  });
}
